<template>
    <v-row class="mt-2 ml-2 mr-2 mb-2" style="background-color: #dce0e6;">
        <v-col cols="5">
            <div>
                <div>
                    <small style="font-size: 16px; font-weight: bold;"> Tempo de Disponibilidade das máquinas por Data e
                        Turno</small>
                    <ejs-grid ref='grid' id='grid' :dataSource="dados" :allowFiltering='true' :allowSorting='true'>
                        <e-columns>
                            <e-column field='data' headerText='Data' width='150'></e-column>
                            <e-column field='turno' headerText='Turno' width='150'></e-column>
                            <e-column field='horas' headerText='Horas' width='150'></e-column>
                            <e-column field='horasProd' headerText='Horas Produtivas' width='150'></e-column>
                            <e-column field='indice' headerText='Índice' width='100'></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </v-col>
        <v-col cols="3">
            <div>
                <div>
                    <small style="font-size: 16px; font-weight: bold;">Detalhamento de Disponibilidade</small>
                    <ejs-grid ref='grid' id='grid' :dataSource="detalhamentos" :allowFiltering='true'
                        :allowSorting='true'>
                        <e-columns>
                            <e-column field='status' headerText='Status' width='150'></e-column>
                            <e-column field='duracao' headerText='Duração' width='150'></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </v-col>
        <v-col cols="4">
            <div>
                <div>
                    <small style="font-size: 16px; font-weight: bold;">Disponibilidade Geral</small>
                    <ejs-grid ref='grid' id='grid' :dataSource="disponibilidade" :allowFiltering='true'
                        :allowSorting='true'>
                        <e-columns>
                            <e-column field='horasDisp' headerText='Hrs Disp.' width='150'></e-column>
                            <e-column field='horasProd' headerText='Hrs Prod.' width='150'></e-column>
                            <e-column field='indice' headerText='Índice de Disp.' width='150'></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import { createApp } from 'vue';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids';

export default {
    name: "tabela_disponibilidade_resumida",
    components: {
        'ejs-grid': GridComponent,
        'e-columns': ColumnsDirective,
        'e-column': ColumnDirective,
    },
    data: () => {
        return {
            dados: [
                {
                    data: '15/01/2003',
                    turno: 'PRIMEIRO TURNO',
                    horas: '65:36:26',
                    horasProd: '8:21:01',
                    indice: '12.73%'
                },
                {
                    data: '19/01/1997',
                    turno: 'SEGUNDO TURNO',
                    horas: '65:36:26',
                    horasProd: '8:21:01',
                    indice: '18.00%'
                }
            ],
            detalhamentos: [
                {
                    status: 'MÁQUINA OFFLINE',
                    Duracao: '16:24:06'
                },
                {
                    status: 'NÃO INFORMADO',
                    Duracao: '12:37:51'
                },
                {
                    status: 'PRODUZINDO',
                    Duracao: '8:21:01'
                },
                {
                    status: 'DÚVIDA DE PROJETO',
                    Duracao: '4:15:46'
                },
                {
                    status: 'DIMENSIONAL',
                    Duracao: '3:57:38'
                },
                {
                    status: 'FALTA DE MATERIAL',
                    Duracao: '3:42:38'
                },
            ],
            disponibilidade: [
                {
                    horasDisp: '65:36:26',
                    horasProd: '8:21:01',
                    indice: '12.73%'
                }
            ],
            count: 0
        }
    },

    computed: {
        appCharts() {
            return useAppCharts();
        }
    }
}
</script>
