<template>
  <v-app-bar color="primary" class="pt-0 pb-0" height="50">
    <small class="ml-2" style="">
      <img src="/assets/nxlite/images/logo_nxerp-04.png" alt="Logo NX" style="max-width: 7%;">
    </small>
    <v-spacer></v-spacer>
    <v-btn color="#43A047" variant="elevated" prepend-icon="mdi-monitor-dashboard" class="mr-4" @click="open_factory">MEUS DASHBOARDS</v-btn>
   
  </v-app-bar>
</template>

<script>
  import { useAppStore } from "../stores/app";
  import { useAppCharts } from "../stores/appCharts"

  export default {
    name: "appNavbar",
    data() { return { count: 0, }; },
    computed: {appStore() { return useAppStore(); }, },
    mounted() { },
    methods: {
      TesteRouter() { this.$router.push({ name: "/app/operador-apontamento" }); },
      onlogout() { useAppStore().reset_app() },
      open_factory() { useAppCharts().showModal = true },
      open_recurso() { useAppCharts().open_disponibilidade_resumida = true },
    },
  };
</script>
