import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-033657fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","flex-direction":"column","align-items":"center"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_text = _resolveComponent("v-card-text")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card_text, { class: "text-lg-body-1 text-xl-h6 pt-0 pb-2 pb-lg-0 pb-xl-2 pb-md-0 text-center font-weight-bold text-uppercase" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($options.appStore.apontamento_atual.turno), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", {
      id: "gauge_render",
      style: _normalizeStyle([{"min-width":"200px","display":"flex","align-items":"flex-start"}, $data.display == 'md' ? 'min-height: 80px;' : 'min-height: 100px' ])
    }, null, 4 /* STYLE */),
    _createVNode(_component_v_card_text, { class: "px-0 py-1 pt-lg-0 pt-xl-2 font-weight-bold text-xl-h6" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($data.display != 'md' ? 'Disponibilidade de Hoje' : 'Dispon. de Hoje'), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}