import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pe-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_list = _resolveComponent("v-list")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appLoadMessage.showMessage,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($options.appLoadMessage.showMessage) = $event)),
    "max-width": "600",
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list, {
        class: "py-2",
        "bg-color": "primary",
        color: "primary",
        elevation: "12",
        rounded: "lg"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, {
            "prepend-icon": "$vuetify-outline",
            title: $options.appLoadMessage.message
          }, {
            prepend: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_icon, {
                  color: "primary",
                  size: "x-large"
                })
              ])
            ]),
            append: _withCtx(() => [
              _createVNode(_component_v_progress_circular, {
                color: "white",
                indeterminate: "disable-shrink",
                size: "16",
                width: "2"
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["title"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}