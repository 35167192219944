import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "control-section",
  style: {"background-color":"white"}
}
const _hoisted_2 = { align: "center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_e_series = _resolveComponent("e-series")
  const _component_e_series_collection = _resolveComponent("e-series-collection")
  const _component_ejs_chart = _resolveComponent("ejs-chart")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appCharts.showGabriel,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($options.appCharts.showGabriel) = $event)),
    transition: "dialog-bottom-transition",
    fullscreen: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, { fluid: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar, { color: "primary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    icon: "mdi-close",
                    onClick: _cache[0] || (_cache[0] = $event => ($options.appCharts.showGabriel = false))
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ejs_chart, {
                    style: {"display":"block"},
                    theme: _ctx.theme,
                    align: "center",
                    id: "chartcontainer",
                    title: _ctx.title,
                    primaryXAxis: _ctx.primaryXAxis,
                    primaryYAxis: _ctx.primaryYAxis,
                    chartArea: _ctx.chartArea,
                    width: _ctx.width,
                    axes: _ctx.axes,
                    tooltip: _ctx.tooltip,
                    legendSettings: _ctx.legendSettings
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_e_series_collection, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_e_series, {
                            dataSource: _ctx.seriesData,
                            type: "Column",
                            xName: "Tempo",
                            yName: "DEU_Temp",
                            name: _ctx.teste,
                            width: "2",
                            marker: _ctx.marker
                          }, null, 8 /* PROPS */, ["dataSource", "name", "marker"]),
                          _createVNode(_component_e_series, {
                            dataSource: _ctx.seriesData,
                            type: "Spline",
                            xName: "Tempo",
                            yName: "Maquina_1",
                            yAxisName: "yAxis2",
                            name: "Máquina1",
                            width: "2",
                            marker: _ctx.circleMarker
                          }, null, 8 /* PROPS */, ["dataSource", "marker"]),
                          _createVNode(_component_e_series, {
                            dataSource: _ctx.seriesData,
                            type: "Spline",
                            xName: "Tempo",
                            yName: "Maquina_2",
                            yAxisName: "yAxis3",
                            name: "Máquina2",
                            width: "2",
                            marker: _ctx.triangleMarker
                          }, null, 8 /* PROPS */, ["dataSource", "marker"]),
                          _createVNode(_component_e_series, {
                            dataSource: _ctx.seriesData,
                            type: "Spline",
                            xName: "Tempo",
                            yName: "Maquina_3",
                            yAxisName: "yAxis4",
                            name: "Máquina3",
                            width: "2",
                            marker: _ctx.diamondMarker
                          }, null, 8 /* PROPS */, ["dataSource", "marker"]),
                          _createVNode(_component_e_series, {
                            dataSource: _ctx.seriesData,
                            type: "Spline",
                            xName: "Tempo",
                            yName: "Maquina_4",
                            yAxisName: "yAxis5",
                            name: "Máquina4",
                            width: "2",
                            marker: _ctx.rectangleMarker
                          }, null, 8 /* PROPS */, ["dataSource", "marker"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["theme", "title", "primaryXAxis", "primaryYAxis", "chartArea", "width", "axes", "tooltip", "legendSettings"])
                ])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}