<template>
    <div class="buttons">
        <v-row class="pt-0 pb-3 ml-1 ml-sm-1" :style="display == 'xs' ? 'margin-right: -3px' : ''">
            <v-col cols="12" class="pa-0 ">
                <v-card-text class="text-lg-body-1  text-xl-h6 pt-lg-0 px-0 pt-2 text-center font-weight-bold pb-sm-0 pb-0 pt-sm-1 pt-md-0">ALTERAR STATUS DA PRODUÇÃO:</v-card-text>
            </v-col>
            <v-col class="px-0 py-1" cols="6">
                <v-btn class="px-0" :width="rectangleButtons.width" :height="rectangleButtons.height" size="small"   data-cy="btn-produzindo" color="#43A047" stacked @click="click_produzindo">
                    <v-card-text class="text-body-1 text-sm-caption text-xs-caption font-weight-bold">PRODUZINDO</v-card-text> 
                </v-btn>
            </v-col>
            <v-col class="px-0 py-1" cols="6">
                <v-btn class="px-0" :width="rectangleButtons.width" :height="rectangleButtons.height" size="small"  color="#1E88E5"  data-cy="btn-manutencao" stacked @click="click_manutencao">
                    <v-card-text class="text-body-1 text-sm-caption text-xs-caption font-weight-bold ">MANUTENÇÃO</v-card-text> 
                </v-btn>
            </v-col>
            <v-col class="px-0 py-1" cols="6">
                <v-btn class="px-0" :width="rectangleButtons.width" :height="rectangleButtons.height" size="small"  color="#F9A825" data-cy="btn-setup" stacked @click="click_setup">
                    <v-card-text class="text-body-1 text-sm-caption text-xs-caption font-weight-bold">SETUP</v-card-text> 
                </v-btn>
            </v-col>
            <v-col class="px-0 py-1" cols="6">
                <v-btn class="px-0" :width="rectangleButtons.width" :height="rectangleButtons.height" size="small"  color="#D50000" data-cy="btn-parada" stacked @click="motivo_de_parada">
                    <v-card-text class="text-body-1 text-sm-caption text-xs-caption font-weight-bold">PARADO</v-card-text> 
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { useAppStore } from "../../stores/app";
import { useAppApontamento } from "../../stores/appApontamento";
import { useResponsiveSizes } from '../utils/style'
import { useDisplay } from 'vuetify'

export default {
  name: "eventosApontamento",
  data() {
    return { 
        awesome: false,
        rectangleButtons: useResponsiveSizes().rectangleButtons,  
        display: useDisplay().name
        
    };
  },
  computed: {
    appStore() {
      return useAppStore();
    },
  },
  methods: {
    async click_manutencao() {
      useAppStore().load_actions = {
        message: "ABRINDO APONTAMENTO DE MANUTENÇÃO",
        showMessage: true,
      };

      this.appStore.apontamento_atual.tipo = "Manutenção";

      this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup =
        "Manutenção";

      const json_update = {
        tipo: this.appStore.apontamento_atual.tipo,
        motivo_de_parada: null,
        desc_motivo_parada: null,
      };

      await useAppApontamento().update_apontamento(
        this.appStore.apontamento_atual.name,
        json_update
      );

      useAppStore().load_actions.showMessage = false;
    },

    async click_setup() {
      useAppStore().load_actions = {
        message: "ABRINDO APONTAMENTO DE SETUP",
        showMessage: true,
      };

      this.appStore.apontamento_atual.tipo = "Setup";

      this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup =
        "Setup";

      const json_update = {
        tipo: this.appStore.apontamento_atual.tipo,
        motivo_de_parada: null,
        desc_motivo_parada: null,
      };

      await useAppApontamento().update_apontamento(
        this.appStore.apontamento_atual.name,
        json_update
      );

      useAppStore().load_actions.showMessage = false;
    },

    async motivo_de_parada() {
      useAppStore().load_actions = {
        message: "ABRINDO APONTAMENTO DE MOTIVO DE PARADA",
        showMessage: true,
      };

      this.appStore.apontamento_atual.tipo = "Parada";

      this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup = null;

      const json_update = {
        tipo: this.appStore.apontamento_atual.tipo,
        setup: 0,
        manutencao: 0,
        motivo_de_parada: null,
        desc_motivo_parada: null,
      };

      await useAppApontamento().update_apontamento(
        this.appStore.apontamento_atual.name,
        json_update
      );

      useAppStore().load_actions.showMessage = false;
    },

    async click_produzindo() {
      useAppStore().load_actions = {
        message: "ABRINDO APONTAMENTO",
        showMessage: true,
      };

      if (this.appStore.apontamento_atual.name) {
        this.appStore.apontamento_atual.tipo = "Produção";
        await useAppApontamento().update_apontamento(
          this.appStore.apontamento_atual.name,
          { tipo: this.appStore.apontamento_atual.tipo }
        );
        useAppStore().load_actions.showMessage = false;
      } else {
        await useAppApontamento().abrir_apontamento(
          this.appStore.maquina_sel.id
        );
        useAppStore().load_actions.showMessage = false;
      }
    },
  },
};
</script>