<template>
    <v-dialog v-model="appCharts.show_desempenho_da_empresa" transition="dialog-bottom-transition" fullscreen>
        <v-container fluid height="100vh">
            <v-card height="100%">
                <v-toolbar color="primary">
                    <v-btn icon="mdi-close" @click="appCharts.show_desempenho_da_empresa = false"></v-btn>
                    <v-menu transition="scale-transition">
                        <template v-slot:activator="{ props }">
                            <v-btn color="white" v-bind="props">
                                Recarregar em: {{ timeSelect }}
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item v-for="(item, i) in items" :key="i" style="cursor: pointer;">
                                <v-btn width="100%" @click="onclickTime(item.title)">{{ item.title }}</v-btn>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <!-- Filtros Multiselect -->
                    <v-row justify="center">
                        <v-col cols="2" class="d-flex align-center">
                            <ejs-multiselect width="250" :id="'multiselect1'" sortOrder="ascending"
                                :allowFiltering="allowFiltering" :filtering="filtering" :dataSource="grupData"
                                :fields="fields" placeholder="Grupos" :mode="delimiterMode">
                            </ejs-multiselect>
                        </v-col>

                        <v-col cols="2" class="d-flex align-center">
                            <ejs-multiselect width="250" :id="'multiselect2'" sortOrder="ascending"
                                :allowFiltering="allowFiltering" :filtering="filtering" :dataSource="machineData"
                                :fields="fields" placeholder="Máquinas" :mode="delimiterMode">
                            </ejs-multiselect>
                        </v-col>

                        <v-col cols="2" class="d-flex align-center">
                            <ejs-multiselect width="250" :id="'multiselect3'" sortOrder="ascending"
                                :allowFiltering="allowFiltering" :filtering="filtering" :dataSource="shiftData"
                                :fields="fields" placeholder="Turnos" :mode="delimiterMode">
                            </ejs-multiselect>
                        </v-col>
                    </v-row>

                    <v-btn class="mr-4" @click="openFilterDialog">Filtrar Por Data</v-btn>

                    <!-- Modal de Filtro por Data -->
                    <v-dialog v-model="dialog" max-width="600">
                        <v-card class="pt-1 pb-1 pl-1 pr-1" max-width="800" height="600">
                            <v-container fluid>
                                <v-row class="mt-0 mb-0 pt-0 pb-0">
                                    <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0">
                                        <h3>Filtrado por:</h3>
                                        <v-row justify="space-between">
                                            <v-col cols="5">
                                                <ejs-calendar :min="minDate" :max="maxDate" :value="startDate"
                                                    @change="onStartDateChange">
                                                </ejs-calendar>
                                            </v-col>
                                            <v-col cols="6" style="margin-left: 13px;">
                                                <ejs-calendar :min="minDate" :max="maxDate" :value="endDate"
                                                    @change="onEndDateChange">
                                                </ejs-calendar>
                                            </v-col>
                                        </v-row>
                                        <span id="date_range_label">
                                            Período selecionado:
                                            {{ startDate && endDate ? startDate.toLocaleDateString() + ' a ' +
                                                endDate.toLocaleDateString()
                                                : 'não selecionado' }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0 mb-0 pt-0 pb-0">
                                    <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0">
                                        <div class="control-styles" style="padding-top: 30px">
                                            <label class="h4">Por Tempo: </label>
                                            <ejs-multiselect id="sample-list3" :dataSource="valores"
                                                :mode="delimiterMode" :placeholder="tempo">
                                            </ejs-multiselect>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row justify='center' class="mt-2 mb-0 pt-0 pb-0">
                                        <v-btn color="#04b834" @click="onclickAplicar">Aplicar</v-btn>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-dialog>

                    <v-btn class="ml-2 mr-5" style="background-color: #04b834;" color="white"
                        @click='onclickAplicar'>Aplicar</v-btn>
                </v-toolbar>
                <multiple_charts_desempenho_da_empresa/>
            </v-card>
        </v-container>
    </v-dialog>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import { CalendarComponent } from "@syncfusion/ej2-vue-calendars";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import multiple_charts_desempenho_da_empresa from '../charts/multiple_charts.desempenho_da_empresa.vue'

export default {
    name: 'desempenho_da_empresa',
    components: {
        'ejs-calendar': CalendarComponent,
        'ejs-multiselect': MultiSelectComponent,
        multiple_charts_desempenho_da_empresa
    },
    data() {
        return {
            //modal
            dialog: false,

            //calendario
            minDate: new Date(2024, 1, 1), // Ajuste conforme necessário
            maxDate: new Date(2026, 1, 1), // Ajuste conforme necessário
            startDate: null,
            endDate: null,

            //multiselect
            defaultMode: 'Default',
            boxMode: 'box',
            delimiterMode: 'Delimiter',
            allowFiltering: true,
            grupData: ['Grupo1', 'Grupo2', 'Grupo3', 'Grupo4'],
            machineData: ['Máquina1', 'Máquina2', 'Máquina3', 'Máquina4'],
            shiftData: ['Turno1', 'Turno2', 'Turno3', 'Turno4'],

            //Recarregar em: 
            items: [
                { title: 'OFF' },
                { title: 'AUTO' },
                { title: '5S' },
                { title: '10S' },
                { title: '30S' },
                { title: '1M' },
                { title: '5M' },
                { title: '10M' },
                { title: '15M' },
                { title: '30M' },
                { title: '1H' },
                { title: '2H' },
                { title: '1D' },
            ],
        };
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
    },
    methods: {
        onclickTime(time) {
            this.timeSelect = time;
        },
        onclickAplicar() {
            alert('Filtros aplicados com sucesso!');
        },
        onStartDateChange(args) {
            this.startDate = args.value;
        },
        onEndDateChange(args) {
            this.endDate = args.value;
        },
        openFilterDialog() {
            this.dialog = true;
        },
    },
};
</script>
