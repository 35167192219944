<template>
    <v-dialog v-model="appCharts.show_disponibilidade_resumida" transition="dialog-bottom-transition" fullscreen>
        <v-container fluid>
            <v-card height="100%">
                <v-toolbar color="primary">
                    <v-btn icon="mdi-close" @click="appCharts.show_disponibilidade_resumida = false"></v-btn>
                    <v-menu transition="scale-transition">
                        <template v-slot:activator="{ props }">
                            <v-btn color="white" v-bind="props">
                                Recarregar em: {{ timeSelect }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(item, i) in items" :key="i" style="cursor: pointer">
                                <v-btn width="100%" variant="text" @click="onclickTime(item.title)">{{ item.title
                                    }}</v-btn>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-row justify="center">
                        <v-col cols="2" class="d-flex align-center">
                            <ejs-multiselect width="250" :id="'multiselect1'" sortOrder="Ascending"
                                :allowFiltering="allowFiltering" :filtering="filtering" :dataSource="grupData"
                                :fields="fields" placeholder="Grupos" :mode="delimiterMode">
                            </ejs-multiselect>
                        </v-col>

                        <v-col cols="2" class="d-flex align-center">
                            <ejs-multiselect width="250" :id="'multiselect2'" sortOrder="Ascending"
                                :allowFiltering="allowFiltering" :filtering="filtering" :dataSource="machineData"
                                :fields="fields" placeholder="Máquinas" :mode="delimiterMode">
                            </ejs-multiselect>
                        </v-col>

                        <v-col cols="2" class="d-flex align-center">
                            <ejs-multiselect width="250" :id="'multiselect3'" sortOrder="Ascending"
                                :allowFiltering="allowFiltering" :filtering="filtering" :dataSource="shiftData"
                                :fields="fields" placeholder="Turnos" :mode="delimiterMode">
                            </ejs-multiselect>
                        </v-col>
                    </v-row>
                    <v-btn class="" @click="dialog = true">Filtrar</v-btn>
                    <v-btn variant="tonal">Aplicar</v-btn> <!--"Aplicar" do toolbar, sem funcionalidade-->
                </v-toolbar>
                <v-dialog v-model="dialog" max-width="600" height="600">
                    <v-card class="pt-1 pb-1 pl-1 pr-1" max-width="450" height="300">
                        <v-container fluid>
                            <v-row class="mt-0 mb-0 pt-0 pb-0">
                                <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0">
                                    <h3>Filtrado por:</h3>
                                    <label class="h4">Por período: </label>
                                    <ejs-daterangepicker style="
											height: 40px;
											font-size: 16px;
											font-weight: 600;
											width: 100%;
										" :format="'dd/MM/yyyy'" :value="defaultDateRange" @change="onDateChange"></ejs-daterangepicker>
                                </v-col>
                            </v-row>

                            <v-row class="mt-0 mb-0 pt-0 pb-0">
                                <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0">
                                    <div class="control-styles" style="padding-top: 20px">
                                        <label class="h4">Por Tempo: </label>
                                        <ejs-multiselect id="sample-list3" :dataSource="sportsData"
                                            :mode="delimiterMode" :placeholder="waterMark"></ejs-multiselect>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="mt-2 mb-0 pt-0 pb-0">
                                <v-btn color="green">Aplicar</v-btn>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-dialog>
                <v-divider class="mb-2 mt-2"></v-divider>
                <pie_disponibilidede_resumida />

                <tabela_disponibilidade_resumida />
            </v-card>
        </v-container>
    </v-dialog>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import tabela_disponibilidade_resumida from "../charts/tabela_disponibilidade_resumida.vue";
import { DateRangePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import pie_disponibilidede_resumida from "./pie_disponibilidede_resumida.vue";

export default {
    name: "disponibilidade_resumida",
    components: {
        tabela_disponibilidade_resumida,
        "ejs-daterangepicker": DateRangePickerComponent,
        "ejs-multiselect": MultiSelectComponent,
        pie_disponibilidede_resumida,
    },
    data() {
        return {
            dialog: false,
            waterMark: "Favorite Sports",
            defaultMode: "Default",
            boxMode: "Box",
            delimiterMode: "Delimiter",
            allowFiltering: true,
            grupData: ["Grupo1", "Grupo2", "Grupo3", "Grupo4"],
            machineData: ["Máquina1", "Máquina2", "Máquina3", "Máquina4"],
            shiftData: ["Turno1", "Turno2", "Turno3", "Turno4"],
            items: [
                { title: "OFF" },
                { title: "AUTO" },
                { title: "5S" },
                { title: "10S" },
                { title: "30S" },
                { title: "1M" },
                { title: "5M" },
                { title: "10M" },
                { title: "15M" },
                { title: "30M" },
                { title: "1H" },
                { title: "2H" },
                { title: "1D" },
            ],
            timeSelect: null
        };
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
    },
    methods: {
        onclickTime(time) {
            this.timeSelect = time;
        }
    }
};
</script>
