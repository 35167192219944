<template>
  <div class="detalheapontamento">
    <v-tabs v-model="tab" bg-color="primary" color="white" slider-color="#90A4AE" height="30" selected-class="color-tab">

      <v-tab value="historico" class="font-weight-bold" style="width: 40%; min-width: 20%; max-width: 350px; height: 100%">HISTÓRICO</v-tab>

    </v-tabs>
    <v-tabs-window v-model="tab" style="width: 100%">
      <v-tab-item value="historico">
        <v-row id="container-events-mp" cols="12" class="pt-0">
          <v-col cols="12" xs="12" sm="12" md="12" id="v-col-historico">
            <v-card color="#EEEEEE" class="cardEvents" rounded="0">
              <v-toolbar height="45" id="toolbarHist" color="#37474F">
                <div class="d-flex justify-center align-center">
                  <v-btn
                    @click="onShowHistorico"
                    color="#EEEEEE"
                    variant="flat"
                    text
                    prepend-icon="mdi mdi-magnify"
                  >
                    <v-card-text
                      v-if="
                        display == 'lg' || display == 'xl' || display == 'xxl'
                      "
                      class="pa-0"
                      >HISTÓRICOS ANTERIORES</v-card-text
                    >
                    <v-card-text class="pa-0" v-else> HIST. ANT.</v-card-text>
                  </v-btn>
                  <v-checkbox
                    class="pt-0 pb-0 mr-5"
                    label="FALTANDO INFORMAÇÕES"
                    hide-details="false"
                    style="margin-bottom: none"
                    v-model="appStore.filtro_apontamentos_faltando_informacoes"
                  ></v-checkbox>
                </div>
              </v-toolbar>
              <div style="background-color: #e1e1e3; display: block">
                <ejs-grid
                  :dataSource="appStore.apontamentos_historico"
                  ref="ejsGrid"
                  :height="
                    display == 'xl' || display == 'xxl'
                      ? 'calc(100vh - 410px)'
                      : display == 'lg'
                      ? 'calc(100vh - 370px)'
                      : '60vh'
                  "
                  width="100%"
                  :rowDataBound="onRowDataBound"
                  :enableHover="false"
                  :allowSelection="false"
                  id="histHoje"
                >
                  <e-columns>
                    <e-column
                      headerText="EDITAR"
                      field="status"
                      :template="'columnTemplate'"
                      textAlign="Center"
                    ></e-column>

                    <e-column
                      headerText="SENSOR"
                      :template="'statusTemplate'"
                      textAlign="Center"
                    ></e-column>

                    <e-column
                      headerText="STATUS PRODUÇÃO"
                      field="tipo"
                      :template="'statusProducao'"
                      textAlign="Center"
                    ></e-column>

                    <e-column
                      headerText="INÍCIO"
                      :template="'inicioTemplate'"
                      textAlign="Center"
                    ></e-column>

                    <e-column
                      headerText="FIM"
                      :template="'fimTemplate'"
                      textAlign="Center"
                    ></e-column>

                    <e-column
                      headerText="OP"
                      :template="'ordem_producao'"
                      field="ordem_de_producao"
                      textAlign="Center"
                    ></e-column>

                    <e-column
                      headerText="TURNO"
                      field="turno"
                      :template="'turno'"
                      textAlign="Center"
                    ></e-column>

                    <e-column
                      headerText="MOTIVO DE PARADA"
                      :template="'motivoParada'"
                      field="desc_motivo_parada"
                      textAlign="Left"
                    ></e-column>

                    <e-column
                      headerText="OPERADOR"
                      field="nome_operador"
                      :template="'operador'"
                      textAlign="center"
                    ></e-column>

                    <e-column
                      headerText="OBSERVAÇÃO"
                      :template="'obsTemplate'"
                      textAlign="Center"
                    ></e-column
                    >>

                    <!-- <e-column headerText="APONTAMENTO" field="name"   textAlign="Center" ></e-column> -->
                  </e-columns>

                  <template v-slot:statusTemplate="{ data }">
                    <div class="d-flex justify-center content-center">
                      <v-card
                        v-if="verify_status_sensor(data.status_sensor)"
                        :color="status_color(data.status_sensor)"
                        style="
                          min-width: 70px;
                          height: 61px;
                          align-content: center;
                          border-radius: 0px;
                        "
                      >
                        <v-icon size="40">mdi-lan-connect</v-icon>
                      </v-card>
                      <v-card
                        v-else
                        :color="'#37474F'"
                        style="
                          min-width: 70px;
                          height: 61px;
                          align-content: center;
                          border-radius: 0px;
                        "
                      >
                        <v-icon size="40">mdi-lan-disconnect</v-icon>
                      </v-card>
                    </div>
                  </template>

                  <template v-slot:statusProducao="{ data }">
                    <div
                      class="d-flex align-center"
                      :data-setup="'machine_' + data.tipo"
                    >
                      <v-card-text
                        class="pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas"
                        >{{ data.tipo }}</v-card-text
                      >
                    </div>
                  </template>

                  <template v-slot:ordem_producao="{ data }">
                    <div
                      class="d-flex align-center"
                      :data-setup="'machine_' + data.tipo"
                    >
                      <v-btn
                        variant="text"
                        size="35"
                        @click="ShowOrdemDeProducaoHistorico(data)"
                        color="secondary"
                        class="mr-1 ml-2 d-flex justify-right"
                      >
                        <v-icon
                          :color="data.tipo == 'Setup' ? 'black' : 'white'"
                          size="35"
                          >mdi-magnify</v-icon
                        >
                      </v-btn>

                      <v-btn
                        v-if="data.ordem_de_producao"
                        variant="text"
                        size="35"
                        @click="ShowDetalheOS(data)"
                        color="secondary"
                        class="mx-1"
                      >
                        <v-icon
                          :color="data.tipo == 'Setup' ? 'black' : 'white'"
                          size="35"
                          >mdi-folder-open</v-icon
                        >
                      </v-btn>
                      <v-card-text
                        class="pa-0 text-start text-ellipsis font-weight-bold text-body-1 class-2linhas"
                        >{{ data.ordem_de_producao }}</v-card-text
                      >
                    </div>
                  </template>

                  <template v-slot:columnTemplate="{ data }">
                    <div class="actions" :data-setup="'machine_' + data.tipo">
                      <v-btn
                        size="60"
                        flat
                        @click="ShowApontamento(data)"
                        color="transparent"
                      >
                        <v-icon size="35">mdi mdi-file-edit-outline</v-icon>
                      </v-btn>
                    </div>
                  </template>

                  <template v-slot:inicioTemplate="{ data }">
                    <div :data-setup="'machine_' + data.tipo">
                      {{ retornaDataFormatada(data.dt_inicio) }}
                    </div>
                  </template>

                  <template v-slot:fimTemplate="{ data }">
                    <div :data-setup="'machine_' + data.tipo">
                      {{ retornaDataFormatada(data.dt_fim) }}
                    </div>
                  </template>

                  <template v-slot:motivoParada="{ data }">
                    <div
                      v-if="data.tipo === 'Parada'"
                      class="d-flex align-center"
                      :data-setup="'machine_' + data.tipo"
                    >
                      <v-btn
                        size="40"
                        flat
                        @click="ShowMotivoDeParada(data)"
                        color="transparent"
                      >
                        <v-icon size="35">mdi-magnify</v-icon>
                      </v-btn>
                      <v-card-text
                        class="pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas"
                        >{{ data.desc_motivo_parada }}</v-card-text
                      >
                    </div>
                  </template>

                  <template v-slot:turno="{ data }">
                    <div
                      class="d-flex align-center"
                      :data-setup="'machine_' + data.tipo"
                    >
                      <v-card-text
                        class="pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas"
                        >{{ data.turno }}</v-card-text
                      >
                    </div>
                  </template>

                  <template v-slot:operador="{ data }">
                    <div
                      class="d-flex align-center"
                      :data-setup="'machine_' + data.tipo"
                    >
                      <v-card-text
                        class="pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas"
                        >{{ data.nome_operador }}</v-card-text
                      >
                    </div>
                  </template>

                  <template v-slot:obsTemplate="{ data }">
                    <v-tooltip :text="data.observacao">
                      <template v-slot:activator="{ props }">
                        <div
                          v-bind="props"
                          :data-setup="'machine_' + data.tipo"
                        >
                          <v-card-text
                            class="px-2 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas"
                          >
                            {{ data.observacao }}
                          </v-card-text>
                        </div>
                      </template>
                    </v-tooltip>
                  </template>
                </ejs-grid>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-window>
  </div>
</template>

<script scoped>
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Sort,
  dataReady,
} from "@syncfusion/ej2-vue-grids";

import { useAppMaquinas } from "../../stores/appMaquinas";

import { useAppApontamento } from "../../stores/appApontamento";

import { useAppMotivoDeParada } from "../../stores/appMotivosdeParada";

import { useAppStore } from "../../stores/app";

import { useAppOrdemProducao } from "../../stores/appOrdemProducao";

import { formatDate, apontamentos_filtrados } from "../utils/funcoes";

import { useDisplay } from "vuetify";

export default {
  name: "detalheApontamento",
  components: {
    "ejs-grid": GridComponent,
    "e-columns": ColumnsDirective,
    "e-column": ColumnDirective,
  },
  mounted() {
    useAppMaquinas().listar_maquinas();
  },
  provide: {
    grid: [Page, Edit, Toolbar, Sort],
  },
  computed: {
    appStore() {
      return useAppStore();
    },
  },
  watch: {
    "appStore.apontamentos_historico": {
      handler(newVal) {
        this.$refs.ejsGrid.refresh();
      },
      deep: true, // Para observar mudanças profundas no array
    },
    async "appStore.filtro_apontamentos_faltando_informacoes"(newValue) {
      if (newValue) {
      
        const apontamentos_atual = useAppStore().apontamentos_historico;

        if (apontamentos_atual.length == 0) {
          return;
        }

        useAppStore().apontamentos_historico = [];

        apontamentos_filtrados(apontamentos_atual)
          .then((apontamentos_filtrados) => {
            useAppStore().apontamentos_historico = apontamentos_filtrados;
          })
          .catch(async (error) => {
            console.error("error apontamentos_filtrados", error);
            await useAppStore().filtrar_apontamentos(
              false,
              15,
              "detalhe_apontamento"
            );
          });

        this.$refs.ejsGrid.refresh();
      } else {
        await useAppStore().filtrar_apontamentos(
          false,
          15,
          "detalhe_apontamento"
        );
      }
    },
  },
  methods: {
    async ShowOrdemDeProducaoHistorico(data) {
      useAppApontamento().set_apontamento(data);
      await useAppOrdemProducao().listar_ops(0);
      useAppStore().view_apontamento_historico = true;
      useAppOrdemProducao().showModal = true;
    },
    async ShowMotivoDeParada(data) {
      useAppApontamento().selecionar_motivo_de_parada(data);
      await useAppMotivoDeParada().listar_motivos_de_parada();
      useAppStore().showModalMotivoDeParada = true;
    },
    retornaDataFormatada(data) {
      return formatDate(data);
    },
    async ShowDetalheOS(data) {
      await useAppOrdemProducao().listar_files_ordem_producao(
        data.ordem_de_producao
      );
      useAppStore().showModalObsDetalhes = true;
    },
    onShowHistorico() {
      useAppStore().showModalHistorico = true;
    }, //
    ShowApontamento(data) {
      useAppApontamento().titulo_modal = `INÍCIO DO APONTAMENTO: ${formatDate(
        data.dt_inicio
      )} - FIM DO APONTAMENTO: ${formatDate(data.dt_fim)}`;
      useAppApontamento().set_apontamento(data);
      useAppApontamento().showModal = true;
    },
    onRowDataBound(args) {
      switch (args.data.tipo) {
        case "Produção":
          args.row.style.backgroundColor = "#43A047";
          args.row.style.color = "white";
          break;
        case "Parada":
          args.row.style.backgroundColor = "#D50000";
          args.row.style.color = "white";
          break;
        case "Setup":
          args.row.style.backgroundColor = "#F9A825";
          args.row.style.color = "black";
          break;
        case "Manutenção":
          args.row.style.backgroundColor = "#1E88E5";
          args.row.style.color = "white";
          break;
        default:
          args.row.style.backgroundColor = "#f8d7da";
          args.row.style.color = "white";
          break;
      }
    },

    verify_status_sensor(status_sensor = false) {
      return status_sensor === "ACTIVE" || status_sensor === "IDLE";
    },

    status_color(status_sensor = null) {
      if (status_sensor === "ACTIVE") {
        return "#43A047"; // Verde
      } else if (status_sensor === "IDLE") {
        return "#D50000"; // Vermelho
      } else {
        return "#37474F"; // Cinza
      }
    },
  },
  data() {
    return {
      tab: "historico",
      FiltroSelecionado: localStorage.getItem("TypesInformation"),
      ShowInfosFaltando: false,
      noDataText: "SEM INFORMAÇÕES PENDENTES",
      display: useDisplay().name,
    };
  },
};
</script>

<style scoped>
/* o ::v-deep força o sistema a usar a estilização do vuetify */
::v-deep #toolbarHist > .v-toolbar__content {
  display: flex !important;
  padding: 0 !important;
  justify-content: end !important;
}

.e-grid > .e-row > .e-rowcell > #colStatus {
  min-width: 500px !important;
}

::v-deep .v-checkbox input {
  width: 60px !important;
}

/* feito para aumentar a area de click do checkbox */

::v-deep .v-selection-control label {
  margin-bottom: 0px !important;
}

/*Tirando o espaço do label do checkbox */

.e-grid .e-row:hover {
  opacity: 0.9 !important;
}

.class-2linhas {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.cardEvents {
  width: 100%;
}
</style>
