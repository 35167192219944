export function formatDate(input: string): string {
  try {
    const date = new Date(input);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  } catch (error) {
    throw new Error(error);
  }
}

export function CalculaTempoMaquina(DataRequisicao: Date) {
  const GetdataAtual = new Date();
  const diffInSeconds =
    (GetdataAtual.getTime() - DataRequisicao.getTime()) / 1000;

  const hours = Math.floor(diffInSeconds / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  const seconds = Math.floor(diffInSeconds % 60);

  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  return { tempo: formattedTime };
}

export function formatDateSearch(data: string): string {
  try {
    const date = new Date(data);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`;
  } catch (error) {
    throw new Error(error);
  }
}

export function colorStatusParada(tp_parada: string) {
  switch (tp_parada) {
    case "Manutenção":
      return "#1E88E5";
    case "Setup":
      return "#F9A825";
    default:
      return "#D50000";
  }
}

export const validar_faltando_informacao_parada = (apontamento: any) => {
  if (
    apontamento.motivo_de_parada === "" ||
    apontamento.motivo_de_parada === null
  ) {
    return false;
  } else {
    return true;
  }
};

export const validar_faltando_informacao_produzindo_setup = (
  apontamento: any
) => {
  let validado = true;

  if (
    apontamento.ordem_de_producao === "" ||
    apontamento.ordem_de_producao === null
  ) {
    validado = false;
  }

  if (apontamento.nome_operador === "" || apontamento.nome_operador === null) {
    validado = false;
  }

  return validado;
};

export const apontamentos_filtrados = async (apontamento_atual: any) => {
  const apontamentos_retorno = [] as any;

  for (let i = 0; i < apontamento_atual.length; i++) {
    if (apontamento_atual[i].tipo != "Aberto") {
      switch (apontamento_atual[i].tipo) {
        case "Parada":
          if (!validar_faltando_informacao_parada(apontamento_atual[i])) {
            apontamentos_retorno.push(apontamento_atual[i]);
          }
          break;
        case "Setup":
          if (
            !validar_faltando_informacao_produzindo_setup(apontamento_atual[i])
          ) {
            apontamentos_retorno.push(apontamento_atual[i]);
          }
          break;
        case "Produção":
          if (
            !validar_faltando_informacao_produzindo_setup(apontamento_atual[i])
          ) {
            apontamentos_retorno.push(apontamento_atual[i]);
          }
          break;
      }
    }
  }

  return apontamentos_retorno;
};

export const get_cur_turno_by_recurso = async (recurso) => {
  const ds = [
    "domingo",
    "segunda_feira",
    "terca_feira",
    "quarta_feira",
    "quinta_feira",
    "sexta_feira",
    "sabado",
  ];
  const disponibilidade_do_dia =
    ds[moment(frappe.datetime.now_datetime()).weekday()];
  const recurso_doc = await frappe.db.get_doc(
    "Recurso",
    recurso
  );
  const disponibilidade_rec = await recurso_doc['disponibilidade_' + disponibilidade_do_dia]

  if (!disponibilidade_rec) return null;
  const cur_turno = disponibilidade_rec.find(
    (e) => moment(e.inicio) < moment() > moment(e.fim)
  );
  return cur_turno || null;
};
