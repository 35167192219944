import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "buttons mt-2 m-2" }
const _hoisted_2 = { class: "infoprod mt-2 m-2" }
const _hoisted_3 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_4 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_5 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_6 = { style: {"display":"flex","align-items":"center"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appApontamento.showModal,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($options.appApontamento.showModal) = $event)),
    transition: "dialog-bottom-transition",
    fullscreen: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        height: "120",
        color: "#E0E0E0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: $options.onVoltarApontamento,
                color: "secondary",
                variant: "elevated",
                "prepend-icon": "mdi-arrow-left-circle"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("VOLTAR")
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onClick"]),
              _createVNode(_component_v_toolbar_title, { style: {"font-size":"13px","font-weight":"bold"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($options.appApontamento.titulo_modal), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_row, { class: "mt-0 pt-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                class: "mt-0 pt-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, { color: "#EEEEEE" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_toolbar, {
                        color: $options.colorStatusApontamento,
                        class: "toolbar-realtime",
                        height: "40",
                        id: "v-toolbar_production"
                      }, null, 8 /* PROPS */, ["color"]),
                      _createVNode(_component_v_row, { aligin: "center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "5",
                            md: "5"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, [
                                _createVNode(_component_v_row, {
                                  justify: "center",
                                  class: "mt-2"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_text, { class: "text-lg-body-1 py-2 px-0 text-xl-h6 text-center font-weight-bold" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("ALTERAR STATUS DA PRODUÇÃO:")
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_row, { class: "pr-0 ml-n1 pl-1 pt-0 pb-1" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, {
                                      class: "px-0 py-1",
                                      cols: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          id: "btn-style",
                                          color: "#43A047",
                                          width: $data.rectangleButtons.width,
                                          height: $data.rectangleButtons.height,
                                          size: "small",
                                          stacked: "",
                                          onClick: $options.click_produzindo
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, { class: "text-body-1 text-sm-caption text-xs-caption font-weight-bold" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode("PRODUZINDO")
                                              ]),
                                              _: 1 /* STABLE */
                                            })
                                          ]),
                                          _: 1 /* STABLE */
                                        }, 8 /* PROPS */, ["width", "height", "onClick"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, {
                                      class: "px-0 py-1",
                                      cols: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          id: "btn-style",
                                          color: "#1E88E5",
                                          width: $data.rectangleButtons.width,
                                          height: $data.rectangleButtons.height,
                                          size: "small",
                                          stacked: "",
                                          onClick: $options.click_manutencao
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, { class: "text-body-1 text-sm-caption text-xs-caption font-weight-bold" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode("MANUTENÇÃO")
                                              ]),
                                              _: 1 /* STABLE */
                                            })
                                          ]),
                                          _: 1 /* STABLE */
                                        }, 8 /* PROPS */, ["width", "height", "onClick"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, {
                                      class: "px-0 py-1",
                                      cols: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          id: "btn-style",
                                          color: "#F9A825",
                                          width: $data.rectangleButtons.width,
                                          height: $data.rectangleButtons.height,
                                          size: "small",
                                          stacked: "",
                                          onClick: $options.click_setup
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, { class: "text-body-1 text-sm-caption text-xs-caption font-weight-bold" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode("SETUP")
                                              ]),
                                              _: 1 /* STABLE */
                                            })
                                          ]),
                                          _: 1 /* STABLE */
                                        }, 8 /* PROPS */, ["width", "height", "onClick"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, {
                                      class: "px-0 py-1",
                                      cols: "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          id: "btn-style",
                                          color: "#D50000",
                                          width: $data.rectangleButtons.width,
                                          height: $data.rectangleButtons.height,
                                          size: "small",
                                          stacked: "",
                                          onClick: $options.motivo_de_parada
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_text, { class: "text-body-1 text-sm-caption text-xs-caption font-weight-bold" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode("PARADO")
                                              ]),
                                              _: 1 /* STABLE */
                                            })
                                          ]),
                                          _: 1 /* STABLE */
                                        }, 8 /* PROPS */, ["width", "height", "onClick"])
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "7",
                            md: "7"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_2, [
                                _createVNode(_component_v_row, {
                                  justify: "center",
                                  class: "mt-2"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_text, { class: "text-lg-body-1 text-xl-h6 text-center font-weight-bold py-2" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("INFORMAÇÕES DA PRODUÇÃO:")
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_row, { class: "pr-0 pl-1 pt-0 pb-1" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, {
                                      cols: "6",
                                      class: "py-3 py-lg-2"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_3, [
                                          _createVNode(_component_v_text_field, {
                                            density: $data.inputText.size,
                                            variant: $data.inputTextVariant.variant,
                                            "hide-details": "false",
                                            modelValue: 
                            $options.appApontamento.resourceApontamento.nome_operador
                          ,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((
                            $options.appApontamento.resourceApontamento.nome_operador
                          ) = $event)),
                                            label: "OPERADOR",
                                            readonly: "",
                                            style: {"font-weight":"bold"},
                                            id: "input_user_shop",
                                            placeholder: "Não Informado",
                                            "persistent-placeholder": "",
                                            clearable: "",
                                            "onClick:clear": $options.ClearFuncionario,
                                            onClick: $options.ShowFuncionarios
                                          }, null, 8 /* PROPS */, ["density", "variant", "modelValue", "onClick:clear", "onClick"]),
                                          _createVNode(_component_v_btn, {
                                            size: $data.squareButtons.size,
                                            onClick: $options.ShowFuncionarios,
                                            class: "ml-2",
                                            color: "secondary"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_icon, {
                                                size: "x-large",
                                                icon: "mdi-magnify"
                                              })
                                            ]),
                                            _: 1 /* STABLE */
                                          }, 8 /* PROPS */, ["size", "onClick"])
                                        ])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, {
                                      cols: "6",
                                      class: "py-3 py-lg-2"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_4, [
                                          _createVNode(_component_v_text_field, {
                                            density: $data.inputText.size,
                                            variant: $data.inputTextVariant.variant,
                                            "hide-details": "false",
                                            modelValue: 
                            $options.appApontamento.resourceApontamento.observacao
                          ,
                                            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((
                            $options.appApontamento.resourceApontamento.observacao
                          ) = $event)),
                                            style: {"font-weight":"bold"},
                                            label: "OBSERVAÇÃO",
                                            id: "input_user_shop",
                                            placeholder: "Não Informado ",
                                            "persistent-placeholder": "",
                                            clearable: "",
                                            "onClick:clear": $options.ClearObs,
                                            onClick: $options.ShowObs,
                                            readonly: ""
                                          }, null, 8 /* PROPS */, ["density", "variant", "modelValue", "onClick:clear", "onClick"]),
                                          _createVNode(_component_v_btn, {
                                            size: $data.squareButtons.size,
                                            class: "ml-2",
                                            onClick: $options.ShowObs,
                                            color: "secondary"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_icon, {
                                                size: "x-large",
                                                icon: "mdi-file-edit-outline"
                                              })
                                            ]),
                                            _: 1 /* STABLE */
                                          }, 8 /* PROPS */, ["size", "onClick"])
                                        ])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    (
                        ($options.appApontamento.resourceApontamento.tipo == 'Parada' &&
                          !$options.appApontamento.isSetupClicked) ||
                        $options.appApontamento.mostrarMotivoParada
                      )
                                      ? (_openBlock(), _createBlock(_component_v_col, {
                                          key: 0,
                                          cols: "12",
                                          sm: "12",
                                          md: "6",
                                          lg: "6",
                                          xl: "6",
                                          class: "py-3 py-lg-2"
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_5, [
                                              _createVNode(_component_v_text_field, {
                                                density: $data.inputText.size,
                                                variant: $data.inputTextVariant.variant,
                                                "hide-details": "false",
                                                label: 
                            $options.appApontamento.resourceApontamento.tipo ==
                              'Parada' &&
                            $options.appApontamento.resourceApontamento
                              .desc_motivo_parada == null
                              ? 'INFORMAR O MOTIVO DE PARADA'
                              : 'MOTIVO PARADA'
                          ,
                                                readonly: "",
                                                modelValue: 
                            $options.appApontamento.resourceApontamento
                              .desc_motivo_parada
                          ,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((
                            $options.appApontamento.resourceApontamento
                              .desc_motivo_parada
                          ) = $event)),
                                                style: {"font-weight":"bold"},
                                                placeholder: "Não Informado",
                                                "persistent-placeholder": "",
                                                clearable: "",
                                                onClick: $options.ShowMp,
                                                "onClick:clear": $options.ClearMotivoParada
                                              }, null, 8 /* PROPS */, ["density", "variant", "label", "modelValue", "onClick", "onClick:clear"]),
                                              (
                            $options.appApontamento.resourceApontamento
                              .motivo_de_parada_manutencao_setup !== 'Setup'
                          )
                                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                                    key: 0,
                                                    size: $data.squareButtons.size,
                                                    class: "ml-2",
                                                    onClick: $options.ShowMp,
                                                    color: "secondary"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_icon, {
                                                        size: "x-large",
                                                        icon: "mdi-magnify"
                                                      })
                                                    ]),
                                                    _: 1 /* STABLE */
                                                  }, 8 /* PROPS */, ["size", "onClick"]))
                                                : _createCommentVNode("v-if", true)
                                            ])
                                          ]),
                                          _: 1 /* STABLE */
                                        }))
                                      : _createCommentVNode("v-if", true),
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      sm: "12",
                                      md: $options.appApontamento.resourceApontamento.tipo != 'Parada'  ? '12' : '6',
                                      class: "py-3 py-lg-2"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_6, [
                                          _createVNode(_component_v_text_field, {
                                            density: $data.inputText.size,
                                            variant: $data.inputTextVariant.variant,
                                            "hide-details": "false",
                                            modelValue: 
                            $options.appApontamento.resourceApontamento.ordem_de_producao
                          ,
                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((
                            $options.appApontamento.resourceApontamento.ordem_de_producao
                          ) = $event)),
                                            label: "ORDEM DE PRODUÇÃO",
                                            readonly: "",
                                            style: {"font-weight":"bold"},
                                            id: "input_user_shop",
                                            placeholder: "Não Informado",
                                            "persistent-placeholder": "",
                                            clearable: "",
                                            onClick: $options.ShowOP,
                                            "onClick:clear": $options.ClearOP
                                          }, null, 8 /* PROPS */, ["density", "variant", "modelValue", "onClick", "onClick:clear"]),
                                          _createVNode(_component_v_btn, {
                                            size: $data.squareButtons.size,
                                            class: "ml-2",
                                            onClick: $options.ShowOP,
                                            color: "secondary"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_icon, {
                                                size: "x-large",
                                                icon: "mdi-magnify"
                                              })
                                            ]),
                                            _: 1 /* STABLE */
                                          }, 8 /* PROPS */, ["size", "onClick"])
                                        ])
                                      ]),
                                      _: 1 /* STABLE */
                                    }, 8 /* PROPS */, ["md"])
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}