import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, {
      class: "pt-0 pb-3 ml-1 ml-sm-1",
      style: _normalizeStyle($data.display == 'xs' ? 'margin-right: -3px' : '')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          class: "pa-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, { class: "text-lg-body-1 text-xl-h6 pt-lg-0 px-0 pt-2 text-center font-weight-bold pb-sm-0 pb-0 pt-sm-1 pt-md-0" }, {
              default: _withCtx(() => [
                _createTextVNode("ALTERAR STATUS DA PRODUÇÃO:")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          class: "px-0 py-1",
          cols: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "px-0",
              width: $data.rectangleButtons.width,
              height: $data.rectangleButtons.height,
              size: "small",
              "data-cy": "btn-produzindo",
              color: "#43A047",
              stacked: "",
              onClick: $options.click_produzindo
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, { class: "text-body-1 text-sm-caption text-xs-caption font-weight-bold" }, {
                  default: _withCtx(() => [
                    _createTextVNode("PRODUZINDO")
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["width", "height", "onClick"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          class: "px-0 py-1",
          cols: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "px-0",
              width: $data.rectangleButtons.width,
              height: $data.rectangleButtons.height,
              size: "small",
              color: "#1E88E5",
              "data-cy": "btn-manutencao",
              stacked: "",
              onClick: $options.click_manutencao
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, { class: "text-body-1 text-sm-caption text-xs-caption font-weight-bold" }, {
                  default: _withCtx(() => [
                    _createTextVNode("MANUTENÇÃO")
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["width", "height", "onClick"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          class: "px-0 py-1",
          cols: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "px-0",
              width: $data.rectangleButtons.width,
              height: $data.rectangleButtons.height,
              size: "small",
              color: "#F9A825",
              "data-cy": "btn-setup",
              stacked: "",
              onClick: $options.click_setup
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, { class: "text-body-1 text-sm-caption text-xs-caption font-weight-bold" }, {
                  default: _withCtx(() => [
                    _createTextVNode("SETUP")
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["width", "height", "onClick"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          class: "px-0 py-1",
          cols: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "px-0",
              width: $data.rectangleButtons.width,
              height: $data.rectangleButtons.height,
              size: "small",
              color: "#D50000",
              "data-cy": "btn-parada",
              stacked: "",
              onClick: $options.motivo_de_parada
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, { class: "text-body-1 text-sm-caption text-xs-caption font-weight-bold" }, {
                  default: _withCtx(() => [
                    _createTextVNode("PARADO")
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["width", "height", "onClick"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["style"])
  ]))
}