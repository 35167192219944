<template>
    <v-toolbar :color="colorStatusApontamento" class="toolbar-realtime mb-md-5 mb-sm-3 mb-xs-3 mt-1" height="40" id="v-toolbar_production">
        <v-row class="pl-0 d-flex">
            <v-col cols="1" class="pr-0 pt-0 pb-0">
                <v-card v-if="verify_status_sensor" :color="status_color" style="width: 100%; height: 100%;"
                    class="d-flex align-center justify-center">
                    <v-icon :size="display != 'xs' ? '35' : '15'">mdi-lan-connect</v-icon>
                </v-card>

                <v-card v-else :color="'#37474F'" style="width: 100%; height: 100%;"
                    class="d-flex align-center justify-center">
                    <v-icon  :size="display != 'xs' ? '35' : '15'">mdi-lan-disconnect</v-icon>
                </v-card>
            </v-col>
            <v-col cols="11" class="pl-0 d-flex align-center">
                <small v-if="appStore.apontamento_atual.name" class="ml-2 text-xl-h5 text-md-subtitle-1 text-sm-caption text-caption font-weight-bold pa-0 content-center">
                    TEMPO REAL: {{ tempExecucao }} | ID : {{ appStore.maquina_sel.id }} |
                </small>

                <v-btn v-if="appStore.maquina_sel && appStore.maquina_sel.nome" id="btn-maquina" variant="tonal"
                    class=" px-0" @click="ShowMaquinas"
                    :color="appStore.apontamento_atual.tipo === 'Produção' ? '#1B5E20' : '#E53935'"
                    style="font-weight: bold; font-size: 16px; color: white;" size="small" width="35%">
                    <v-card-title class="py-0 text-md-subtitle-1 text-sm-caption font-weight-medium text-caption reticencias" >
                        {{ appStore.maquina_sel.nome }}
                    </v-card-title>
                </v-btn>

                <small class="" style="font-weight: bold;font-size: 16px;"> | {{ appStore.maquina_sel.leitor }}</small>

                <v-btn class=" px-0" v-if="appStore.apontamento_atual.op"
                    style="font-weight: bold;font-size: 16px;color:white" @click="ShowDetalheOS" variant="tonal"
                     size="small">
                     <v-icon icon="mdi-folder-open"></v-icon>
                    {{display != 'sm' && display != 'xs' ? 'Desenhos': ''}}
                </v-btn>
            </v-col>
        </v-row>
    </v-toolbar>
</template>

<script>
import { useAppStore } from '../../stores/app';
import { useAppMaquinas } from '../../stores/appMaquinas';
import { useAppOrdemProducao } from "../../stores/appOrdemProducao";
import { CalculaTempoMaquina } from '../utils/funcoes';
import { useResponsiveSizes } from '../utils/style';
import { useDisplay } from 'vuetify';

export default {
    name: "headerApontamento",
    components: {},
    data() {
        return {
            tempExecucao: '00:00:00',
            isMenuOpen: false,
            time: 0,
            filter_maquinas: '',
            btnCabecalho: useResponsiveSizes().btnCabecalho,
            display: useDisplay().name
        };
    },
    actions: { async selecionar_maquina(maquina) { this.maquina_sel = maquina; } },
    computed: {
        resourceMaquinas() {
            const filter = this.filter_maquinas.toUpperCase();
            return useAppMaquinas().resourceMaquinas.filter(maquina =>
                maquina.nome.toUpperCase().includes(filter)
            );
        },
        appStore() { return useAppStore(); },
        verify_status_sensor() {
            const status = useAppStore().apontamento_atual.state_sensor;
            return status === 'ACTIVE' || status === 'IDLE';
        },
        status_color() {
            const status = this.appStore.maquina_sel.state_sensor;
            if (status === 'ACTIVE') {
                return '#43A047'; // Verde 
            } else if (status === 'IDLE') {
                return '#D50000'; // Vermelho 
            } else {
                return '#37474F'; // Cinza 
            }
        },
        isMachineSelected() { return (useAppStore().maquina_sel.id != null) },

        colorStatusApontamento() {
            try {
                if (useAppStore().apontamento_atual.tipo) {
                    switch (useAppStore().apontamento_atual.tipo) {
                        case 'Parada':
                            return "#D50000"
                        case 'Produção':
                            return '#43A047';
                        case 'Setup':
                            return '#F9A825';
                        case 'Manutenção':
                            return '#1E88E5';
                        default:
                            return 'primary';
                    }
                } else 
                { 
                    return 'primary'; 
                }
            } catch (e) { throw 'Error color status apontamento' + e }
        },
    },
    watch: {
        isMachineSelected(newVal) {
            if (!newVal) {
                this.isMenuOpen = true;
            }
            else {
                this.inicia_count_machine()
            }
        }
    },
    mutations: { selecionar_maquina(maquina) { this.maquina_sel = maquina; }, },

    methods: {
        inicia_count_machine() {
            clearInterval(this.time);
            setInterval(() => {
                this.tempoApontamento()
                this.time++;
            }, 1000);
        },

        tempoApontamento() {
            this.tempExecucao = CalculaTempoMaquina(
                new Date(useAppStore().apontamento_atual.dt_inicio)
            ).tempo
        },
        ShowMaquinas() {
            const appMaquinas = useAppMaquinas();
            appMaquinas.showModal = true;
        },
        async ShowDetalheOS() {
            const appOrdemProducao = useAppOrdemProducao();
            const appStore = useAppStore();
            await appOrdemProducao.listar_files_ordem_producao(appStore.apontamento_atual.op);
            appStore.showModalObsDetalhes = true;
        },
        async SelecionarMaquina(maquina) {
            const appStore = useAppStore();
            await appStore.selecionar_maquina(maquina);
            await this.atualizarMotivosDeParada();
            this.isMenuOpen = false;
        },
        async atualizarMotivosDeParada() {
            const appMotivoDeParada = useAppMotivoDeParada();
            const appStore = useAppStore();
            appMotivoDeParada.apontamento_name = appStore.apontamento_atual.name;
            await appMotivoDeParada.listar_motivos_de_parada();
            appStore.showModalMotivoDeParada = true;
        },
    },

    mounted() { useAppMaquinas().showModal = true; },
}
</script>

<style scoped>
.reticencias {
  display: block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

</style>