import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7527c350"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detalheapontamento" }
const _hoisted_2 = { class: "d-flex justify-center align-center" }
const _hoisted_3 = { style: {"background-color":"#e1e1e3","display":"block"} }
const _hoisted_4 = { class: "d-flex justify-center content-center" }
const _hoisted_5 = ["data-setup"]
const _hoisted_6 = ["data-setup"]
const _hoisted_7 = ["data-setup"]
const _hoisted_8 = ["data-setup"]
const _hoisted_9 = ["data-setup"]
const _hoisted_10 = ["data-setup"]
const _hoisted_11 = ["data-setup"]
const _hoisted_12 = ["data-setup"]
const _hoisted_13 = ["data-setup"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_tab = _resolveComponent("v-tab")
  const _component_v_tabs = _resolveComponent("v-tabs")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_checkbox = _resolveComponent("v-checkbox")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_e_column = _resolveComponent("e-column")
  const _component_e_columns = _resolveComponent("e-columns")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_tooltip = _resolveComponent("v-tooltip")
  const _component_ejs_grid = _resolveComponent("ejs-grid")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_tab_item = _resolveComponent("v-tab-item")
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_tabs, {
      modelValue: $data.tab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.tab) = $event)),
      "bg-color": "primary",
      color: "white",
      "slider-color": "#90A4AE",
      height: "30",
      "selected-class": "color-tab"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, {
          value: "historico",
          class: "font-weight-bold",
          style: {"width":"40%","min-width":"20%","max-width":"350px","height":"100%"}
        }, {
          default: _withCtx(() => [
            _createTextVNode("HISTÓRICO")
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_v_tabs_window, {
      modelValue: $data.tab,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.tab) = $event)),
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab_item, { value: "historico" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              id: "container-events-mp",
              cols: "12",
              class: "pt-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  xs: "12",
                  sm: "12",
                  md: "12",
                  id: "v-col-historico"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      color: "#EEEEEE",
                      class: "cardEvents",
                      rounded: "0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_toolbar, {
                          height: "45",
                          id: "toolbarHist",
                          color: "#37474F"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_component_v_btn, {
                                onClick: $options.onShowHistorico,
                                color: "#EEEEEE",
                                variant: "flat",
                                text: "",
                                "prepend-icon": "mdi mdi-magnify"
                              }, {
                                default: _withCtx(() => [
                                  (
                        $data.display == 'lg' || $data.display == 'xl' || $data.display == 'xxl'
                      )
                                    ? (_openBlock(), _createBlock(_component_v_card_text, {
                                        key: 0,
                                        class: "pa-0"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("HISTÓRICOS ANTERIORES")
                                        ]),
                                        _: 1 /* STABLE */
                                      }))
                                    : (_openBlock(), _createBlock(_component_v_card_text, {
                                        key: 1,
                                        class: "pa-0"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" HIST. ANT.")
                                        ]),
                                        _: 1 /* STABLE */
                                      }))
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["onClick"]),
                              _createVNode(_component_v_checkbox, {
                                class: "pt-0 pb-0 mr-5",
                                label: "FALTANDO INFORMAÇÕES",
                                "hide-details": "false",
                                style: {"margin-bottom":"none"},
                                modelValue: $options.appStore.filtro_apontamentos_faltando_informacoes,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($options.appStore.filtro_apontamentos_faltando_informacoes) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"])
                            ])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_ejs_grid, {
                            dataSource: $options.appStore.apontamentos_historico,
                            ref: "ejsGrid",
                            height: 
                    $data.display == 'xl' || $data.display == 'xxl'
                      ? 'calc(100vh - 410px)'
                      : $data.display == 'lg'
                      ? 'calc(100vh - 370px)'
                      : '60vh'
                  ,
                            width: "100%",
                            rowDataBound: $options.onRowDataBound,
                            enableHover: false,
                            allowSelection: false,
                            id: "histHoje"
                          }, {
                            statusTemplate: _withCtx(({ data }) => [
                              _createElementVNode("div", _hoisted_4, [
                                ($options.verify_status_sensor(data.status_sensor))
                                  ? (_openBlock(), _createBlock(_component_v_card, {
                                      key: 0,
                                      color: $options.status_color(data.status_sensor),
                                      style: {"min-width":"70px","height":"61px","align-content":"center","border-radius":"0px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { size: "40" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-lan-connect")
                                          ]),
                                          _: 1 /* STABLE */
                                        })
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["color"]))
                                  : (_openBlock(), _createBlock(_component_v_card, {
                                      key: 1,
                                      color: '#37474F',
                                      style: {"min-width":"70px","height":"61px","align-content":"center","border-radius":"0px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { size: "40" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-lan-disconnect")
                                          ]),
                                          _: 1 /* STABLE */
                                        })
                                      ]),
                                      _: 1 /* STABLE */
                                    }))
                              ])
                            ]),
                            statusProducao: _withCtx(({ data }) => [
                              _createElementVNode("div", {
                                class: "d-flex align-center",
                                "data-setup": 'machine_' + data.tipo
                              }, [
                                _createVNode(_component_v_card_text, { class: "pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(data.tipo), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ], 8 /* PROPS */, _hoisted_5)
                            ]),
                            ordem_producao: _withCtx(({ data }) => [
                              _createElementVNode("div", {
                                class: "d-flex align-center",
                                "data-setup": 'machine_' + data.tipo
                              }, [
                                _createVNode(_component_v_btn, {
                                  variant: "text",
                                  size: "35",
                                  onClick: $event => ($options.ShowOrdemDeProducaoHistorico(data)),
                                  color: "secondary",
                                  class: "mr-1 ml-2 d-flex justify-right"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, {
                                      color: data.tipo == 'Setup' ? 'black' : 'white',
                                      size: "35"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("mdi-magnify")
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["color"])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                                (data.ordem_de_producao)
                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                      key: 0,
                                      variant: "text",
                                      size: "35",
                                      onClick: $event => ($options.ShowDetalheOS(data)),
                                      color: "secondary",
                                      class: "mx-1"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, {
                                          color: data.tipo == 'Setup' ? 'black' : 'white',
                                          size: "35"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-folder-open")
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["color"])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                                  : _createCommentVNode("v-if", true),
                                _createVNode(_component_v_card_text, { class: "pa-0 text-start text-ellipsis font-weight-bold text-body-1 class-2linhas" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(data.ordem_de_producao), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ], 8 /* PROPS */, _hoisted_6)
                            ]),
                            columnTemplate: _withCtx(({ data }) => [
                              _createElementVNode("div", {
                                class: "actions",
                                "data-setup": 'machine_' + data.tipo
                              }, [
                                _createVNode(_component_v_btn, {
                                  size: "60",
                                  flat: "",
                                  onClick: $event => ($options.ShowApontamento(data)),
                                  color: "transparent"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, { size: "35" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("mdi mdi-file-edit-outline")
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                              ], 8 /* PROPS */, _hoisted_7)
                            ]),
                            inicioTemplate: _withCtx(({ data }) => [
                              _createElementVNode("div", {
                                "data-setup": 'machine_' + data.tipo
                              }, _toDisplayString($options.retornaDataFormatada(data.dt_inicio)), 9 /* TEXT, PROPS */, _hoisted_8)
                            ]),
                            fimTemplate: _withCtx(({ data }) => [
                              _createElementVNode("div", {
                                "data-setup": 'machine_' + data.tipo
                              }, _toDisplayString($options.retornaDataFormatada(data.dt_fim)), 9 /* TEXT, PROPS */, _hoisted_9)
                            ]),
                            motivoParada: _withCtx(({ data }) => [
                              (data.tipo === 'Parada')
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: "d-flex align-center",
                                    "data-setup": 'machine_' + data.tipo
                                  }, [
                                    _createVNode(_component_v_btn, {
                                      size: "40",
                                      flat: "",
                                      onClick: $event => ($options.ShowMotivoDeParada(data)),
                                      color: "transparent"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { size: "35" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-magnify")
                                          ]),
                                          _: 1 /* STABLE */
                                        })
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                                    _createVNode(_component_v_card_text, { class: "pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(data.desc_motivo_parada), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ], 8 /* PROPS */, _hoisted_10))
                                : _createCommentVNode("v-if", true)
                            ]),
                            turno: _withCtx(({ data }) => [
                              _createElementVNode("div", {
                                class: "d-flex align-center",
                                "data-setup": 'machine_' + data.tipo
                              }, [
                                _createVNode(_component_v_card_text, { class: "pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(data.turno), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ], 8 /* PROPS */, _hoisted_11)
                            ]),
                            operador: _withCtx(({ data }) => [
                              _createElementVNode("div", {
                                class: "d-flex align-center",
                                "data-setup": 'machine_' + data.tipo
                              }, [
                                _createVNode(_component_v_card_text, { class: "pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(data.nome_operador), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ], 8 /* PROPS */, _hoisted_12)
                            ]),
                            obsTemplate: _withCtx(({ data }) => [
                              _createVNode(_component_v_tooltip, {
                                text: data.observacao
                              }, {
                                activator: _withCtx(({ props }) => [
                                  _createElementVNode("div", _mergeProps(props, {
                                    "data-setup": 'machine_' + data.tipo
                                  }), [
                                    _createVNode(_component_v_card_text, { class: "px-2 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(data.observacao), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */)
                                  ], 16 /* FULL_PROPS */, _hoisted_13)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["text"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_e_columns, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_e_column, {
                                    headerText: "EDITAR",
                                    field: "status",
                                    template: 'columnTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "SENSOR",
                                    template: 'statusTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "STATUS PRODUÇÃO",
                                    field: "tipo",
                                    template: 'statusProducao',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "INÍCIO",
                                    template: 'inicioTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "FIM",
                                    template: 'fimTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "OP",
                                    template: 'ordem_producao',
                                    field: "ordem_de_producao",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "TURNO",
                                    field: "turno",
                                    template: 'turno',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "MOTIVO DE PARADA",
                                    template: 'motivoParada',
                                    field: "desc_motivo_parada",
                                    textAlign: "Left"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "OPERADOR",
                                    field: "nome_operador",
                                    template: 'operador',
                                    textAlign: "center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "OBSERVAÇÃO",
                                    template: 'obsTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createTextVNode("> "),
                                  _createCommentVNode(" <e-column headerText=\"APONTAMENTO\" field=\"name\"   textAlign=\"Center\" ></e-column> ")
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["dataSource", "height", "rowDataBound"])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}