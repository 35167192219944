import { Funcionario } from './../app/common/interfaceApp';
import { defineStore } from "pinia";

declare let frappe: any;

export const useAppFuncionarios = defineStore({
  id: "appFuncionarios",
  state: (): {
    showModal: boolean;
    resourceFuncionarios: Funcionario[];
  } => ({
    showModal: false,
    resourceFuncionarios: [],
  }),

  getters: {},
  actions: {
    async listar_funcionarios() {
      try {
        this.resourceFuncionarios = [];

        const res = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_all_operador",
          { limit: 20 }
        );

        res.message.forEach((funcionario: any) => {
          this.resourceFuncionarios.push({
            funcionario: funcionario.funcionario,
            identificacao: funcionario.identificacao,
            foto: funcionario.foto,
            nome: funcionario.nome,
            name_entidade: funcionario.name_entidade,
            name: funcionario.name,
          });
        });
      } catch (error) { throw new Error(error); }
    },

    update_operador_realtime(operador_doc) {
      const prev_resource = this.resourceFuncionarios;
      const new_data = {
        funcionario: operador_doc.funcionario,
        identificacao: operador_doc.identificacao,
        foto: operador_doc.foto,
        nome: operador_doc.nome,
        name_entidade: operador_doc.name_entidade,
        name: operador_doc.name,
        apontamentos: operador_doc.apontamentos,
      };

      const new_resource = prev_resource.map((data) =>
        data.name == operador_doc.name ? new_data : data
      );

      this.resourceFuncionarios = new_resource;
    },

    update_apontamento_realtime(data) {
      if(Object.keys(data).includes('operador')) {
        const { apontamento, operador } = data;
        const prev_resource = this.resourceFuncionarios;
        const new_resource = prev_resource.map((data) => {
          if(data.name != operador.name) return data;
          if(!data.apontamentos) return {...data, apontamentos: [apontamento]} 

          if(data.apontamentos.length == 0) return {...data, apontamentos: [apontamento]} 
          if (Array.isArray(data.apontamentos)) {

            const found_index = data.apontamentos.findIndex(apt => apt.name == apontamento.name)

            let new_apontamentos = data.apontamentos;
            if (found_index == -1) return {...data, apontamentos: new_apontamentos.push(apontamento)};
            new_apontamentos[found_index] = apontamento;
            const new_data = {...data, apontamentos: new_apontamentos};
            return new_data;
          } else { return [] } 
        });

        this.resourceFuncionarios = new_resource;
        console.log(this.resourceFuncionarios);
      }
    },
  }, persist: { enabled: true }
});