import { useDisplay } from 'vuetify'
import { computed } from 'vue'

export function useResponsiveSizes() {
  const { name } = useDisplay()

  const dimensions = computed(() => {
    const sizes = {
      xs: { width: '100%', height: '90%', size: 12, btnSq: 2 },
      sm: { width: '100%', height: '90%', size: 15 },
      md: { width: '90%', height: '90%', size: 16 },
      lg: { width: '100%', height: '90%', size: 17 },
      xl: { width: '100%', height: '', size: 18 },
      xxl: { width: '100%', height: '', size: 18 }
    }
    return sizes[name.value] 
  })

  const rectangleButtons = computed(() => {
    const sizes = {
      xs: { width: '95%', height: 35},
      sm: { width: '95%', height: 65},
      md: { width: '90%', height: 45},
      lg: { width: '95%', height: 50},
      xl: { width: '95%', height: 70},
      xxl: { width: '95%', height: 70}
    }
    return sizes[name.value] 
  })

  const squareButtons = computed(() => {
    const sizes = {
      xs: {size: 27},
      sm: {size: 27},
      md: {size: 28},
      lg: {size: 40},
      xl: {size: 56},
      xxl: {size: 56}
    }
    return sizes[name.value] 
  })

  const btnCabecalho = computed(() => {
    const sizes = {
      xs: { width: '100%', height: '90%'},
      sm: { width: '100%', height: '90%'},
      md: { width: '90%', height: '90%'},
      lg: { width: '100%', height: '90%'},
      xl: { width: '100%', height: ''},
      xxl: { width: '100%', height: ''}
    }
    return sizes[name.value] 
  })

  
  const inputText = computed(() => {
    const sizes = {
      xs: {size: '' },
      sm: {size: '' },
      md: {size: '' },
      lg: {size: 'compact' },
      xl: {size: 'default' },
      xxl: {size: 'default' }
    }
    return sizes[name.value] 
  })

  const inputTextVariant = computed(() => {
    const sizes = {
      xs: {variant: 'underlined' },
      sm: {variant: 'underlined' },
      md: {variant: 'underlined' },
      lg: {variant: undefined },
      xl: {variant: undefined },
      xxl: {variant: undefined }
    }
    return sizes[name.value] 
  })


  const gritTitle = computed(() => {
    const sizes = {
      xs: 'text-caption' ,
      sm: 'text-caption' ,
      md: 'text-caption' ,
      lg: 'text-caption' ,
      xl: 'text-subtitle-1' ,
      xxl: 'text-h6' 
    }
    return sizes[name.value] 
  })

  
  const gridsCompleteProduction = computed(() => {
    const sizes = {
      xs: 'position: fixed; bottom: 0; width: 100%; top: 306px; padding-right: 19px;',
      sm: 'position: fixed; bottom: 0; width: 100%; top: 306px; padding-right: 19px;',
      md: 'position: fixed; bottom: 0; width: 100%; top: 306px; padding-right: 19px;',
      lg: 'position: fixed; bottom: 0; width: 100%; top: 306px; padding-right: 19px;',
      xl: 'position: fixed; bottom: 0; width: 100%; top: 355px; padding-right: 19px;',
      xxl: 'position: fixed; bottom: 0; width: 100%; top: 355px; padding-right: 19px;' 
    }
    return sizes[name.value] 
  })

  const gridHeight = computed(() => {
    const sizes = {
      xs: 200 ,
      sm: 242 ,
      md: 250 ,
      lg: 160 ,
      xl: 275 ,
      xxl: 370 
    }
    return sizes[name.value] 
  })

  const gridTd = computed(() => {
    const sizes = {
      xs: '370' ,
      sm: '370' ,
      md: '370' ,
      lg: '200' ,
      xl: '275' ,
      xxl: '370' 
    }
    return sizes[name.value] 
  })
  


  return {  
    dimensions, 
    rectangleButtons, 
    squareButtons, 
    inputText, 
    btnCabecalho, 
    inputTextVariant, 
    gritTitle,
    gridHeight,
    gridTd,
    gridsCompleteProduction
  }
}