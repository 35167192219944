import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df64ab5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "infoprod pt-0 pb-0" }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_4 = {
  key: 0,
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_5 = { style: {"display":"flex","align-items":"center"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, { class: "px-2 pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0 pl-xxl-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          class: "pa-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, { class: "text-lg-body-1 text-xl-h6 text-center font-weight-bold pt-0 pb-2 pb-sm-1 pt-sm-1 pt-lg-0 pb-1 pt-md-0" }, {
              default: _withCtx(() => [
                _createTextVNode("INFORMAÇÕES DA PRODUÇÃO:")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          cols: "6",
          class: "px-2 py-lg-2 pt-lg-0 pt-md-4 pt-sm-2"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_text_field, {
                "hide-details": "false",
                density: $data.inputText.size,
                variant: $data.inputTextVariant.variant,
                class: "mb-0 pb-0",
                label: "OPERADOR",
                modelValue: $options.appStore.apontamento_atual.funcionario.nome,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($options.appStore.apontamento_atual.funcionario.nome) = $event)),
                readonly: "",
                style: {"font-weight":"bold","width":"90%"},
                placeholder: "Não Informado",
                "persistent-placeholder": "",
                clearable: "",
                "onClick:clear": $options.ClearFuncionario,
                onClick: $options.ShowFuncionarios
              }, null, 8 /* PROPS */, ["density", "variant", "modelValue", "onClick:clear", "onClick"]),
              _createVNode(_component_v_btn, {
                size: $data.squareButtons.size,
                onClick: $options.ShowFuncionarios,
                color: "secondary",
                "data-cy": "operador_tempo_real"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    size: "x-large",
                    icon: "mdi-magnify"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["size", "onClick"])
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          cols: "6",
          class: "px-2 py-lg-2 pt-lg-0 pt-md-4 pt-sm-2"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_v_text_field, {
                density: $data.inputText.size,
                "hide-details": "false",
                variant: $data.inputTextVariant.variant,
                label: "OBSERVAÇÃO",
                modelValue: $options.appStore.apontamento_atual.observacao,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($options.appStore.apontamento_atual.observacao) = $event)),
                readonly: "",
                style: {"font-weight":"bold","width":"90%"},
                id: "input_obs",
                placeholder: "Não Informado",
                "persistent-placeholder": "",
                clearable: "",
                "onClick:clear": $options.ClearObs,
                onClick: $options.ShowObs
              }, null, 8 /* PROPS */, ["density", "variant", "modelValue", "onClick:clear", "onClick"]),
              _createVNode(_component_v_btn, {
                size: $data.squareButtons.size,
                "data-cy": "obs_tempo_real",
                color: "secondary",
                onClick: $options.ShowObs
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    size: "x-large",
                    icon: "mdi-file-edit-outline"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["size", "onClick"])
            ])
          ]),
          _: 1 /* STABLE */
        }),
        (($options.appStore.apontamento_atual.tipo == 'Parada'))
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 0,
              cols: "12",
              sm: "12",
              md: "6",
              lg: "6",
              xl: "6",
              class: "px-2 py-lg-1 py-sm-2 py-md-3 py-lg-2"
            }, {
              default: _withCtx(() => [
                ((!_ctx.awesome))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_v_text_field, {
                        density: $data.inputText.size,
                        "hide-details": "false",
                        variant: $data.inputTextVariant.variant,
                        label: ($options.appStore.apontamento_atual.tipo == 'Parada') && ($options.appStore.apontamento_atual.desc_motivo_parada == null) ? 'INFORMAR O MOTIVO DE PARADA' : 'MOTIVO PARADA',
                        readonly: "",
                        modelValue: $options.appStore.apontamento_atual.desc_motivo_parada,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($options.appStore.apontamento_atual.desc_motivo_parada) = $event)),
                        style: {"font-weight":"bold","width":"90%"},
                        placeholder: "Não Informado",
                        "persistent-placeholder": "",
                        clearable: "",
                        "onClick:clear": $options.ClearMotivoParada,
                        onClick: $options.ShowMp
                      }, null, 8 /* PROPS */, ["density", "variant", "label", "modelValue", "onClick:clear", "onClick"]),
                      ($options.appStore.apontamento_atual.motivo_de_parada_manutencao_setup !== 'Setup')
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            size: $data.squareButtons.size,
                            onClick: $options.ShowMp,
                            "data-cy": "motivo_de_parada_tempo_real",
                            color: "secondary"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, {
                                size: "x-large",
                                icon: "mdi-magnify"
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["size", "onClick"]))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_v_col, {
          cols: "12",
          class: "px-2 py-lg-1 py-sm-2 py-md-3 py-lg-2",
          sm: "12",
          md: $options.appStore.apontamento_atual.tipo != 'Parada'  ? '12' : '6'
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_v_text_field, {
                density: $data.inputText.size,
                "hide-details": "false",
                variant: $data.inputTextVariant.variant,
                label: "ORDEM DE PRODUÇÃO",
                readonly: "",
                modelValue: $options.appStore.apontamento_atual.op,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($options.appStore.apontamento_atual.op) = $event)),
                style: {"font-weight":"bold","width":"90%"},
                id: "teste2",
                placeholder: "Não Informado",
                "persistent-placeholder": "",
                clearable: "",
                "onClick:clear": $options.ClearOP,
                onClick: $options.ShowOP
              }, null, 8 /* PROPS */, ["density", "variant", "modelValue", "onClick:clear", "onClick"]),
              _createVNode(_component_v_btn, {
                size: $data.squareButtons.size,
                onClick: $options.ShowOP,
                color: "secondary",
                "data-cy": "ordem_de_producao_tempo_real"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    size: "x-large",
                    icon: "mdi-magnify"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["size", "onClick"])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["md"]),
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, {
              style: {"cursor":"pointer","margin-top":"-20px","font-weight":"bold"},
              class: "py-0 pl-0 mb-1 brokenText"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($options.appStore.apontamento_atual.op), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}