<template>
    <v-dialog v-model="appCharts.showGabriel" transition="dialog-bottom-transition" fullscreen>
        <v-container fluid>
            <v-card>
                <v-toolbar color="primary">
                    <v-btn icon="mdi-close" @click="appCharts.showGabriel = false"></v-btn>
                </v-toolbar>
                <div class="control-section" style="background-color: white">
                    <div align="center">
                        <ejs-chart style="display: block" :theme="theme" align="center" id="chartcontainer"
                            :title="title" :primaryXAxis="primaryXAxis" :primaryYAxis="primaryYAxis"
                            :chartArea="chartArea" :width="width" :axes="axes" :tooltip="tooltip"
                            :legendSettings="legendSettings">

                            <e-series-collection>
                                <e-series :dataSource="seriesData" type="Column" xName="Tempo" yName="DEU_Temp"
                                    :name="teste" width="2" :marker="marker">
                                </e-series>
                                <e-series :dataSource="seriesData" type="Spline" xName="Tempo" yName="Maquina_1"
                                    yAxisName="yAxis2" name="Máquina1" width="2" :marker="circleMarker">
                                </e-series>
                                <e-series :dataSource="seriesData" type="Spline" xName="Tempo" yName="Maquina_2"
                                    yAxisName="yAxis3" name="Máquina2" width="2" :marker="triangleMarker">
                                </e-series>
                                <e-series :dataSource="seriesData" type="Spline" xName="Tempo" yName="Maquina_3"
                                    yAxisName="yAxis4" name="Máquina3" width="2" :marker="diamondMarker">
                                </e-series>
                                <e-series :dataSource="seriesData" type="Spline" xName="Tempo" yName="Maquina_4"
                                    yAxisName="yAxis5" name="Máquina4" width="2" :marker="rectangleMarker">
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>

                    </div>
                </div>
            </v-card>
        </v-container>
    </v-dialog>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import { Browser } from "@syncfusion/ej2-base";
import {
    ChartComponent,
    SeriesDirective,
    SeriesCollectionDirective,
    AnnotationsDirective,
    AnnotationDirective,
    SplineSeries,
    ChartAnnotation,
    ColumnSeries,
    Category,
    Tooltip,
} from "@syncfusion/ej2-vue-charts";

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Fluent2";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
    .replace(/-dark/i, "Dark")
    .replace(/contrast/i, "Contrast")
    .replace(/-highContrast/i, "HighContrast");

export default {
    name: "gabriel",
    components: {
        "ejs-chart": ChartComponent,
        "e-series-collection": SeriesCollectionDirective,
        "e-series": SeriesDirective,
        "e-annotations": AnnotationsDirective,
        "e-annotation": AnnotationDirective,
    },
    data: function () {
        return {
            teste: ['Disponibilidade: 50%','meta: 60'],
            seriesData: [
                { Tempo: "18/08/2018", DEU_Temp: 35, Maquina_1: 31, Maquina_2: 33, Maquina_3: 30, Maquina_4: 29 },
                { Tempo: "19/08/2018", DEU_Temp: 40, Maquina_1: 33, Maquina_2: 35, Maquina_3: 31, Maquina_4: 30 },
                { Tempo: "20/08/2018", DEU_Temp: 25, Maquina_1: 30, Maquina_2: 29, Maquina_3: 30, Maquina_4: 28 },
                { Tempo: "21/08/2018", DEU_Temp: 20, Maquina_1: 29, Maquina_2: 26, Maquina_3: 35, Maquina_4: 25 },
            ],

            primaryXAxis: {
                valueType: "Category",
                majorGridLines: { width: 0 },
                majorTickLines: { width: 0 },
                minorGridLines: { width: 0 },
            },

            primaryYAxis: {
                minimum: 0,
                maximum: 100,
                interval: 5,
                lineStyle: { width: 0 },
                majorTickLines: { width: 0 },
                labelFormat: "{value}%",
            },
            chartArea: {
                border: {
                    width: 0,
                },
            },
            axes: [
                {
                    majorGridLines: { width: 0 },
                    lineStyle: { width: 0 },
                    rowIndex: 0,
                    opposedPosition: true,
                    minimum: 24,
                    maximum: 36,
                    interval: 2,
                    name: "yAxis2",
                    visible: false,
                    labelFormat: "{value}",
                },
                {
                    majorGridLines: { width: 0 },
                    lineStyle: { width: 0 },
                    rowIndex: 1,
                    opposedPosition: true,
                    minimum: 20,
                    maximum: 40,
                    interval: 2,
                    name: "yAxis3",
                    visible: false,
                    labelFormat: "{value}",
                },
                {
                    majorGridLines: { width: 0 },
                    lineStyle: { width: 0 },
                    rowIndex: 2,
                    opposedPosition: true,
                    minimum: 25,
                    maximum: 35,
                    interval: 2,
                    name: "yAxis4",
                    visible: false,
                    labelFormat: "{value}",
                },
                {
                    majorGridLines: { width: 0 },
                    lineStyle: { width: 0 },
                    rowIndex: 3,
                    opposedPosition: true,
                    minimum: 20,
                    maximum: 35,
                    interval: 2,
                    name: "yAxis5",
                    visible: false,
                    labelFormat: "{value}",
                },
            ],
            Marker: { visible: true, width: 7, height: 7 },
            circleMarker: { visible: true, width: 7, height: 7, isFilled: true },
            triangleMarker: { visible: true, width: 7, height: 7, isFilled: true },
            diamondMarker: { visible: true, width: 7, height: 7, isFilled: true },
            rectangleMarker: { visible: true, width: 7, height: 7, isFilled: true },
            width: Browser.isDevice ? "100%" : "75%",
            tooltip: { enable: true },
            legendSettings: {
                visible: true,
                position: "Bottom", // Legenda fixa embaixo
                alignment: "Center", // Centraliza a legenda abaixo do gráfico
                textStyle: {
                    fontSize: '12px', // Ajusta o tamanho do texto da legenda, se necessário
                    color: '#333' // Altere a cor se quiser algo mais visível
                }

            },
            title: "Desempenho da Empresa",
        };
    },
    provide: {
        chart: [SplineSeries, ColumnSeries, Category, Tooltip, ChartAnnotation],
    },
    methods: {},
    computed: {
        appCharts() {
            return useAppCharts();
        },
    },
};
</script>