<template>
  <v-dialog v-model="appStore.showModalHistorico" transition="dialog-bottom-transition" fullscreen>
    <v-toolbar color="primary"> </v-toolbar>
    <v-card>
      <v-container fluid class="mt-0">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-toolbar height="80" dark color="#37474F">
              <v-btn @click="onShowHistorico()" color="#EEEEEE" class="ml-3 mr-2 py-0 align-center" variant="flat"
                height="42">
                <v-icon icon="mdi-arrow-left-circle" size="35"></v-icon>
                <v-card-text v-if="display == 'lg' || display == 'xl' || display == 'xxl'"
                  class="py-0 font-weight-bold text-h5">VOLTAR</v-card-text>
              </v-btn>
              <v-toolbar-title class="font-weight-bold">HISTÓRICOS ANTERIORES</v-toolbar-title>
              <div>
                <v-checkbox style="color: white; font-weight: bold" class="mt-5 pt-0 pb-0 mr-4" :label="display == 'lg' || display == 'xl' || display == 'xxl'
                  ? 'FALTANDO INFORMAÇÕES'
                  : 'FALT. INFOS.'
                  " v-model="appStore.filtro_apontamentos_faltando_informacoes"></v-checkbox>
              </div>
              <div>
                <ejs-daterangepicker ref="dateRangePicker" style="
                    height: 40px;
                    font-size: 16px;
                    font-weight: 600;
                    width: 100%;
                  " :format="'dd/MM/yyyy'" :value="defaultDateRange" @keydown="applyDateRangeFormat"
                  @click="showCalendar" @change="onDateChange"></ejs-daterangepicker>
                <!-- <ejs-daterangepicker
                                     ref="dateRangePicker" style="height: 40px; font-size: 16px; font-weight: 600; width: 100%;"
                                    :format="'dd/MM/yyyy'" :value="defaultDateRange" 
                                    @change="onDateChange"></ejs-daterangepicker> -->
              </div>
              <template v-slot:append>
                <v-btn variant="flat" class="ml-3 mr-2 py-0 align-center" color="secondary" height="42"
                  @click="OnSearchHistoricos">
                  <v-card-text v-if="
                    display == 'lg' || display == 'xl' || display == 'xxl'
                  " class="py-0 font-weight-bold text-h5">PESQUISAR</v-card-text>
                  <v-icon icon="mdi-magnify" size="35"></v-icon>
                </v-btn>
              </template>
            </v-toolbar>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pt-0">
            <v-card>
              <ejs-grid :dataSource="appStore.apontamentos_historico" :height="display == 'lg' || display == 'xl' || display == 'xxl'
                ? 'calc(100vh - 240px)'
                : '60vh'
                " width="100%" :rowDataBound="onRowDataBound" pageSize="20" :enableHover="false" :allowPaging="true"
                :allowSelection="false" id="histHoje">
                <e-columns>
                  <e-column headerText="EDITAR" field="status" :template="'columnTemplate'"
                    textAlign="Center"></e-column>

                  <e-column headerText="SENSOR" :template="'statusTemplate'" textAlign="Center"></e-column>

                  <e-column headerText="STATUS PRODUÇÃO" field="tipo" textAlign="Center"></e-column>

                  <e-column headerText="INÍCIO" :template="'inicioTemplate'" textAlign="Center"></e-column>

                  <e-column headerText="FIM" :template="'fimTemplate'" textAlign="Center"></e-column>

                  <e-column headerText="OP" :template="'ordem_producao'" field="ordem_de_producao"
                    textAlign="Center"></e-column>

                  <e-column headerText="TURNO" field="turno" :template="'turno'" textAlign="Center"></e-column>

                  <e-column headerText="MOTIVO DE PARADA" :template="'motivoParada'" field="desc_motivo_parada"
                    textAlign="Left"></e-column>

                  <e-column headerText="OPERADOR" field="nome_operador" :template="'operador'"
                    textAlign="center"></e-column>

                  <e-column headerText="OBSERVAÇÃO" :template="'obsTemplate'" textAlign="Center"></e-column>>

                  <e-column headerText="APONTAMENTO" field="name" textAlign="Center"></e-column>
                </e-columns>

                <template v-slot:statusTemplate="{ data }">
                  <div class="d-flex justify-center content-center">
                    <v-card v-if="verify_status_sensor(data.status_sensor)" :color="status_color(data.status_sensor)"
                      style="
                        min-width: 70px;
                        height: 61px;
                        align-content: center;
                        border-radius: 0px;
                      ">
                      <v-icon size="40">mdi-lan-connect</v-icon>
                    </v-card>
                    <v-card v-else :color="'#37474F'" style="
                        min-width: 70px;
                        height: 61px;
                        align-content: center;
                        border-radius: 0px;
                      ">
                      <v-icon size="40">mdi-lan-disconnect</v-icon>
                    </v-card>
                  </div>
                </template>

                <template v-slot:dt_inicio_template="{ data }">
                  <small style="font-weight: bold; font-size: 15px">{{
                    retornaDataFormatada(data.dt_inicio)
                    }}</small>
                </template>
                <template v-slot:ordem_producao="{ data }">
                  <div class="d-flex align-center">
                    <v-btn variant="text" size="35" @click="ShowOrdemDeProducaoHistorico(data)" color="secondary"
                      class="mr-1 ml-2 d-flex justify-right">
                      <v-icon color="white" size="35">mdi-magnify</v-icon>
                    </v-btn>

                    <v-btn v-if="data.ordem_de_producao" variant="text" size="35" @click="ShowDetalheOS(data)"
                      color="secondary" class="mx-1">
                      <v-icon color="white" size="35">mdi-folder-open</v-icon>
                    </v-btn>
                    <v-card-text class="pa-0 text-start text-ellipsis font-weight-bold text-body-1 class-2linhas">{{
                      data.ordem_de_producao }}</v-card-text>
                  </div>
                </template>

                <template v-slot:columnTemplate="{ data }">
                  <div class="actions">
                    <v-btn size="60" flat @click="ShowApontamento(data)" color="transparent">
                      <v-icon size="35">mdi mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </div>
                </template>

                <template v-slot:inicioTemplate="{ data }">
                  <div>{{ retornaDataFormatada(data.dt_inicio) }}</div>
                </template>
                <template v-slot:fimTemplate="{ data }">
                  <div>{{ retornaDataFormatada(data.dt_fim) }}</div>
                </template>
                <template v-slot:motivoParada="{ data }">
                  <div v-if="data.tipo === 'Parada'" class="d-flex align-center">
                    <v-btn size="40" flat @click="ShowMotivoDeParada(data)" color="transparent">
                      <v-icon size="35">mdi-magnify</v-icon>
                    </v-btn>
                    <v-card-text class="pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas">{{
                      data.desc_motivo_parada }}</v-card-text>
                  </div>
                </template>

                <template v-slot:turno="{ data }">
                  <div v-if="data.tipo === 'Parada'" class="d-flex align-center">
                    <v-card-text class="pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas">{{
                      data.turno }}</v-card-text>
                  </div>
                </template>

                <template v-slot:operador="{ data }">
                  <div v-if="data.tipo === 'Parada'" class="d-flex align-center">
                    <v-card-text class="pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas">{{
                      data.nome_operador }}</v-card-text>
                  </div>
                </template>

                <template v-slot:obsTemplate="{ data }">
                  <v-tooltip :text="data.observacao">
                    <template v-slot:activator="{ props }">
                      <div v-bind="props">
                        <v-card-text class="px-2 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas">
                          {{ data.observacao }}
                        </v-card-text>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
              </ejs-grid>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>


<script>
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Sort,
} from "@syncfusion/ej2-vue-grids";

import { useAppStore } from "../../../stores/app";
import { useAppOrdemProducao } from "../../../stores/appOrdemProducao";
import { useAppApontamento } from "../../../stores/appApontamento";
import { useAppMotivoDeParada } from "../../../stores/appMotivosdeParada";
import { DateRangePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { formatDateSearch } from "../../utils/funcoes";
import { formatDate, apontamentos_filtrados } from "../../utils/funcoes";
import { useDisplay } from "vuetify";

export default {
  name: "historico",
  data() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.defaultDateRange = [today, today];
    return {
      showModalHistorico: false,
      filter_apontamentos_faltando_informacoes: false,
      defaultDateRange: [today, today],
      selectedDates: null,
      display: useDisplay().name,
    };
  },
  watch: {
    async "appStore.filtro_apontamentos_faltando_informacoes"(newVal) {
      if (newVal) {
        await this.onFilterApontamentosSemInformacoes()
      } else {
        this.OnSearchHistoricos();
      }
    },
  },
  methods: {

    showCalendar() {
      this.$refs.dateRangePicker.show(); // Mostra o calendário
    },
    async ShowOrdemDeProducaoHistorico(data) {
      useAppApontamento().set_apontamento(data);
      await useAppOrdemProducao().listar_ops(0);
      useAppStore().view_apontamento_historico = true;
      useAppOrdemProducao().showModal = true;
    },
    async ShowMotivoDeParada(data) {
      useAppApontamento().selecionar_motivo_de_parada(data);
      await useAppMotivoDeParada().listar_motivos_de_parada();
      useAppStore().showModalMotivoDeParada = true;
    },
    retornaDataFormatada(data) {
      return formatDate(data);
    },
    async onFilterApontamentosSemInformacoes() {
      const apontamentos_atual = useAppStore().apontamentos_historico;

      if (apontamentos_atual.length == 0) {
        return;
      }

      useAppStore().apontamentos_historico = [];

      apontamentos_filtrados(apontamentos_atual)
        .then((apontamentos_filtrados) => {
          useAppStore().apontamentos_historico = apontamentos_filtrados;
        })
        .catch(async (error) => {
          console.error("error apontamentos_filtrados", error);
          await useAppStore().filtrar_apontamentos(
            false,
            15,
            "detalhe_apontamento"
          );
        });
    },
    resetDateRange() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      this.defaultDateRange = [today, today];
    },
    onShowHistorico() {
      this.resetDateRange();
      this.filter_apontamentos_faltando_informacoes = false;
      useAppStore().filtrar_apontamentos(false, 15, "onShowHistorico").then(async (res) => {
        if (useAppStore().filtro_apontamentos_faltando_informacoes) {
          await this.onFilterApontamentosSemInformacoes()
        }

        useAppStore().showModalHistorico = false;
      });

    },
    onRowDataBound(args) {
      switch (args.data.tipo) {
        case "Produção":
          args.row.style.backgroundColor = "#43A047";
          args.row.style.color = "white";
          break;
        case "Parada":
          args.row.style.backgroundColor = "#D50000";
          args.row.style.color = "white";
          break;
        case "Setup":
          args.row.style.backgroundColor = "#F9A825";
          args.row.style.color = "white";
          break;
        case "Manutenção":
          args.row.style.backgroundColor = "#1E88E5";
          args.row.style.color = "white";
          break;
        default:
          args.row.style.backgroundColor = "#f8d7da";
          args.row.style.color = "white";
          break;
      }
    },
    async OnSearchHistoricos() {
      if (this.selectedDates) {
        const dt_inicio = formatDateSearch(this.selectedDates[0]).split(" ")[0];
        const dt_fim = formatDateSearch(this.selectedDates[1]).split(" ")[0];

        try {
          useAppStore().apontamentos_historico =
            await useAppStore().get_all_filter_by_date_apontamento(
              dt_inicio,
              dt_fim
            );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        useAppStore().filtrar_apontamentos(false, 15, "OnSearchHistoricos");
      }

      if (useAppStore().filtro_apontamentos_faltando_informacoes) {
        await this.onFilterApontamentosSemInformacoes()
      }
    },
    applyDateRangeFormat(event) {
      const allowedKeys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
      ];

      if (!allowedKeys.includes(event.key)) {
        event.preventDefault();
        return;
      }

      const input = event.target;
      let value = input.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

      // Aplicar formatação: "dd/MM/yyyy - dd/MM/yyyy"
      if (value.length > 0 && value.length <= 2) {
        value = value.slice(0, 2);
      } else if (value.length > 2 && value.length <= 4) {
        value = value.slice(0, 2) + "/" + value.slice(2, 4);
      } else if (value.length > 4 && value.length <= 8) {
        value =
          value.slice(0, 2) + "/" + value.slice(2, 4) + "/" + value.slice(4, 8);
      } else if (value.length > 8 && value.length <= 10) {
        value =
          value.slice(0, 2) +
          "/" +
          value.slice(2, 4) +
          "/" +
          value.slice(4, 8) +
          " - " +
          value.slice(8);
      } else if (value.length > 10 && value.length <= 12) {
        value =
          value.slice(0, 2) +
          "/" +
          value.slice(2, 4) +
          "/" +
          value.slice(4, 8) +
          " - " +
          value.slice(8, 10) +
          "/" +
          value.slice(10);
      } else if (value.length > 12) {
        value =
          value.slice(0, 2) +
          "/" +
          value.slice(2, 4) +
          "/" +
          value.slice(4, 8) +
          " - " +
          value.slice(8, 10) +
          "/" +
          value.slice(10, 12) +
          "/" +
          value.slice(12, 16);
      }

      input.value = value;
    },
    onDateChange(args) {
      this.selectedDates = args.value;
      if (args.length > 0) {
        this.startDate = formatDate(args.value[0]);
        this.endDate = formatDate(args.value[1]);
      }
    },
    ShowApontamento(data) {
      useAppApontamento().titulo_modal = `INCIO DO APONTAMENTO: ${formatDate(
        data.dt_inicio
      )} - FIM DO APONTAMENTO: ${formatDate(data.dt_fim)}`;
      useAppApontamento().set_apontamento(data);
      useAppApontamento().showModal = true;
    },
    async ShowDetalheOS(data) {
      await useAppOrdemProducao().listar_files_ordem_producao(
        data.ordem_de_producao
      );
      useAppStore().showModalObsDetalhes = true;
    },
    statusIcon(status_sensor) {
      //
      return status_sensor === "ACTIVE"
        ? "mdi-lan-connect"
        : "mdi-lan-disconnect";
    },
    verify_status_sensor(status_sensor = false) {
      return status_sensor === "ACTIVE" || status_sensor === "IDLE";
    },
    status_color(status_sensor = null) {
      if (status_sensor === "ACTIVE") {
        return "#43A047"; // Verde
      } else if (status_sensor === "IDLE") {
        return "#D50000"; // Vermelho
      } else {
        return "#37474F"; // Cinza
      }
    },
    statusIconColor(status_sensor) {
      return status_sensor === "ACTIVE" ? "green" : "red";
    }, //
  },
  computed: {
    appStore() {
      return useAppStore();
    },
  },
  components: {
    "ejs-daterangepicker": DateRangePickerComponent,
    "ejs-grid": GridComponent,
    "e-columns": ColumnsDirective,
    "e-column": ColumnDirective,
  },
  provide: {
    grid: [Page, Edit, Toolbar, Sort],
  },
};
</script>

<style scoped>
::v-deep .v-checkbox input {
  width: 60px !important;
}

/* feito para aumentar a area de click do checkbox */

::v-deep .v-selection-control label {
  margin-bottom: 0px !important;
}

.no-border .e-grid {
  border: none;
}

.class-2linhas {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>