import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "criticidade" }
const _hoisted_2 = { class: "dt_entrega" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_row = _resolveComponent("v-row")
  const _component_e_column = _resolveComponent("e-column")
  const _component_e_columns = _resolveComponent("e-columns")
  const _component_v_chip = _resolveComponent("v-chip")
  const _component_ejs_grid = _resolveComponent("ejs-grid")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appOrdemProducao.showModal,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($options.appOrdemProducao.showModal) = $event)),
    transition: "dialog-bottom-transition",
    fullscreen: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: _cache[0] || (_cache[0] = $event => ($options.appOrdemProducao.showModal = false)),
                color: "secondary",
                variant: "elevated",
                "prepend-icon": "mdi-arrow-left-circle"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("VOLTAR")
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("ORDENS DE PRODUÇÃO")
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    density: "compact",
                    modelValue: $data.filter_op,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.filter_op) = $event)),
                    label: "FILTRAR ORDEM DE PRODUÇÃO",
                    id: "filter_op",
                    class: "mt-2 mb-0 pl-1 pr-1 pt-0 pb-0",
                    style: {"width":"100%","font-weight":"bold"},
                    variant: "solo",
                    "bg-color": "secondary",
                    placeholder: "FILTRAR ORDEM DE PRODUÇÃO"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "pt-0 pb-0 mt-1 mb-0 pl-0 pr-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ejs_grid, {
                        allowPaging: true,
                        dataSource: $options.resourceOrdemDeProdocuao,
                        id: "grid_op",
                        recordClick: $options.rowSelectedOp,
                        width: "100%",
                        allowSorting: true,
                        allowFiltering: true
                      }, {
                        columnTemplate: _withCtx(({ data }) => [
                          _createElementVNode("div", _hoisted_1, [
                            (data.criticidade == 'Urgente')
                              ? (_openBlock(), _createBlock(_component_v_chip, {
                                  key: 0,
                                  color: "#C62828",
                                  style: {"font-weight":"bold"}
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(data.criticidade), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */))
                              : (_openBlock(), _createBlock(_component_v_chip, {
                                  key: 1,
                                  color: "#0091EA",
                                  style: {"font-weight":"bold"}
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(data.criticidade), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */))
                          ])
                        ]),
                        templateDateEntrega: _withCtx(({ data }) => [
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("span", null, _toDisplayString($options.formatDate(data.dt_entrega)), 1 /* TEXT */)
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_e_columns, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_e_column, {
                                headerText: "ORDEM DE PRODUÇÃO",
                                field: "name",
                                width: "100",
                                textAlign: "Center"
                              }),
                              _createVNode(_component_e_column, {
                                headerText: "CLIENTE",
                                field: "desc_cliente",
                                width: "100",
                                textAlign: "Left"
                              }),
                              _createVNode(_component_e_column, {
                                headerText: "DESCRIÇÃO",
                                field: "descricao",
                                width: "100",
                                textAlign: "Left"
                              }),
                              _createVNode(_component_e_column, {
                                headerText: "CRITICIDADE",
                                template: 'columnTemplate',
                                field: "criticidade",
                                width: "100",
                                textAlign: "Center"
                              }),
                              _createVNode(_component_e_column, {
                                headerText: "DATA DE ENTREGA",
                                template: 'templateDateEntrega',
                                field: "dt_entrega",
                                width: "100",
                                textAlign: "Center"
                              }),
                              _createVNode(_component_e_column, {
                                headerText: "QUANTIDADE",
                                field: "quantidade",
                                width: "100",
                                textAlign: "Center"
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["dataSource", "recordClick"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}