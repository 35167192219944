import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderApontamento = _resolveComponent("HeaderApontamento")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_col = _resolveComponent("v-col")
  const _component_gaugeDisponibilidade = _resolveComponent("gaugeDisponibilidade")
  const _component_eventosApontamento = _resolveComponent("eventosApontamento")
  const _component_infoprodApontamento = _resolveComponent("infoprodApontamento")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createBlock(_component_v_row, { class: "pt-0 pb-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { cols: "12" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            color: "#EEEEEE",
            rounded: "0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_HeaderApontamento),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "d-md-none d-sm-block mt-1 pb-0 pt-xs-3 pt-sm-0 px-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        color: "primary",
                        class: "d-flex"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_text, { class: "text-caption text-md-body-2 font-weight-black text-break py-1" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, { icon: "mdi-gauge-full" }),
                              _createTextVNode(" DISPON.: Hoje: " + _toDisplayString($options.appStore.maquina_sel.disponibilidade_diaria) + "%", 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_card_text, { class: "text-caption font-weight-black text-md-body-2 text-break py-1" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" TURNO: " + _toDisplayString(), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    md: "2",
                    class: "d-none d-md-flex text-center flex-column align-center px-0 pb-1 pt-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_gaugeDisponibilidade)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "5",
                    md: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_eventosApontamento)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    sm: "7",
                    md: "7"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_infoprodApontamento)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}